<template>
  <div class="vh-100 d-flex justify-content-center">
    <div class="form-access mt-10">
      <form>
        <span>Sign in</span>
        <div class="form-group">
          <input
              type="email"
              v-model="v$.payload.mail.$model"
              :class="[v$.payload.mail.$error ? 'border-danger' : '']"
              class="form-control"
              placeholder="Email Address"
              required
          />
        </div>
        <div class="form-group position-relative">
          <i @click="showPass = !showPass" class="icon position-absolute eye-position"
             :class="[showPass ? 'ion-md-eye' : 'ion-md-eye-off']"></i>
          <input
              :type="showPass ? 'text' : 'password'"
              @keyup.enter="validateForm"
              v-model="v$.payload.password.$model"
              :class="[v$.payload.password.$error ? 'border-danger' : '']"
              class="form-control"
              placeholder="Password"
              required
          />
        </div>
        <div class="text-end">
          <router-link to="/reset">Forgot Password?</router-link>
        </div>
        <!--        <div class="custom-control form-check">-->
        <!--          <input type="checkbox" class="form-check-input" id="form-checkbox" />-->
        <!--          <label class="form-check-label" htmlFor="form-checkbox">-->
        <!--            Remember me-->
        <!--          </label>-->
        <!--        </div>-->
        <button type="button" @click="validateForm" class="btn btn-primary">Sign In</button>
      </form>
      <h2>
        Don't have an account?
        <router-link to="/sign-up">Sign up here</router-link>
      </h2>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useVuelidate} from '@vuelidate/core';
import {required, email, minLength} from '@vuelidate/validators'
import {useToast} from "vue-toastification";
import {AccountSignInRequest, AccountSignUpRequest} from "@/libs/Api/Account";
import Helper from "@/libs/Helper";

export default {
  title: 'MyBit - Login',
  data() {
    return {
      toast: useToast(),
      payload: {
        mail: '',
        password: '',
        type: 2
      },
      showPass: false,
    }
  },
  validations() {
    return {
      payload: {
        mail: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  methods: {
    async validateForm() {
      let _this = this;
      const result = await this.v$.$validate();

      if (!result) {
        return _this.toast.error("All fields are required");
      }
      await _this.signIn();
    },
    async signIn() {
      let _this = this;
      Helper.openLoadingOverlay(_this);
      let data = JSON.parse(JSON.stringify(_this.payload));

      let accountSignInRequest = new AccountSignInRequest(_this);
      accountSignInRequest.setParams(data);
      await accountSignInRequest.fetch(function (content) {
        Helper.closeLoadingOverlay(_this);

        if (content.status === 3) {
          return _this.toast.error("User name or password is wrong!");
        } else if (content.status === 4) {
          _this.toast.error(
              "Your verification code has been expired , try again"
          );
          _this.verificationSent = false;
          return _this.payload.verifyCode = "";
        } else if (content.status === 5) {
          return _this.toast.error("Verification code is wrong!");
        } else if (content.status === 6) {
          return _this.toast.error("User not found!");
        } else if (content.status === 8) {
          return _this.toast.error("User exist!");
        } else if (content.status === 9) {
          return _this.toast.error("Your password is weak!");
        } else if (content.status === 10) {
          return _this.toast.error("User name exist!");
        } else if (content.status === 11) {
          return _this.toast.error("You have been deactivated!");
        } else if (content.status === 12) {
          return _this.toast.error("Wrong national id!");
        } else if (content.status === 7) {
          _this.toast.success("You are logged in.");
        }

        _this.$store.commit("signInUser", content);
        _this.$router.push('/')
      }, function (error) {
        Helper.closeLoadingOverlay(_this);
        console.log(error)
      })
    }
  },
}
</script>

<style scoped>
.eye-position {
  top: 15px;
  right: 15px;
  font-size: 15px;
  cursor: pointer;
}
</style>
