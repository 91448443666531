<template>
  <div v-if="Tickets !== null" class="market-trade chat-container overflow-hidden ticket-h col-11 mx-auto container my-3">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <h2
            class="nav-link active"
            id="limit-tab"
            data-bs-toggle="tab"
            data-bs-target="#limit"
            type="button"
            role="tab"
            aria-controls="limit"
            aria-selected="true"
        >
          Ticket
        </h2>
      </li>
    </ul>

    <div class="tab-content h-100 px-0" id="myTabContent" ref="refChatLogPS" style="overflow-x:hidden!important;">
      <div
          class="tab-pane fade show active h-100"
          id="limit"
          role="tabpanel"
          aria-labelledby="limit-tab"
      >


        <section class="px-0 h-100">
          <div class="container px-0">

            <div class="row d-flex justify-content-center px-0">
              <div class="col-12">

                <div class="card w-100 bg-transparent position-relative" id="chat2">
                  <div class="card-body chat-background" data-mdb-perfect-scrollbar="true">

                    <template v-for="item in Tickets" :key="item.ticketId">
                      <div  v-if="item.userInfo.userId !== user.userId" class="d-flex flex-row justify-content-start mb-4">
                        <img class="rounded-circle" :src="[item.userInfo.selfieFileData ?  baseUrl+'media/gallery/profile/'+item.userInfo.selfieFileData : '/img/avatar.svg']"
                             alt="avatar 1" style="width: 45px; height: 100%;">
                        <div>
                          <div class="small p-2 ms-3 mb-1 rounded-3" style="font-size: 14px;background-color: #f5f6f7;">
                            <div v-if="item.fileData &&  item.fileData!==null && item.fileData.includes('jpg')"
                                 :style="`background-image : url(${baseUrl + item.fileData})`"
                                 class="chat-pic rounded object-cover object-center"
                            ></div>
                            <p>{{ item.content }}</p>
                          </div>
                          <p class="small ms-3 mb-3 rounded-3 text-muted">
                            {{item.createDate.slice(0,10)}}
                            -
                            {{item.createDate.slice(11,16)}}
                          </p>
                        </div>
                      </div>

                      <div v-else class="d-flex flex-row justify-content-end">
                        <div>
                          <div class="small p-2 me-3 mb-1 text-white rounded-3 bg-primary my-chat-log" style="font-size: 14px">
                            <div v-if="item.fileData &&  item.fileData!==null && item.fileData.includes('jpg')"
                                 :style="`background-image : url(${baseUrl + item.fileData})`"
                                 class="chat-pic rounded object-cover object-center"
                            ></div>
                            <p>{{ item.content }}</p>
                          </div>
                          <p class="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end">
                            {{item.createDate.slice(0,10)}}
                            -
                            {{item.createDate.slice(11,16)}}
                          </p>
                        </div>
                        <img :src="[item.userInfo.selfieFileData ? baseUrl+item.userInfo.selfieFileData : '/img/avatar.svg']"
                             alt="avatar" class="rounded-circle" style="width: 45px; height: 100%;">
                      </div>
                    </template>
                  </div>
                  <!--     Footer    -->
                  <div class="card-footer chat-footer ticket-footer text-muted d-flex justify-content-start align-items-center p-3 ">
                    <textarea @keydown.enter.prevent="SendMessage" type="text" v-model="content" class="form-control form-control-lg chat-text-area" id="exampleFormControlInput1"
                              placeholder="Type message" rows="1"></textarea>
                    <span class="chat-footer-buttons ms-3 text-sm cursor-pointer" data-bs-toggle="modal"
                          data-bs-target="#mediaModal"><i class="fas fa-paperclip"></i></span>
                    <span @click="SendMessage" class="chat-footer-buttons ms-3 text-primary cursor-pointer"><i class="fas fa-paper-plane"></i></span>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </section>
      </div>
    </div>

    <!-- Media Modal -->
    <div class="modal fade" id="mediaModal" tabindex="-1" aria-labelledby="mediaModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="mediaModalLabel">Upload media</h5>
            <button type="button" ref="closePicModal" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div @click="$refs.refInputEl1.click()" :style="`background-image : url(${base64ImageSrc})`"
                 class="upload-box rounded-3 bg-secondary cursor-pointer d-flex justify-content-center align-items-center">
              <i v-if="base64ImageSrc === null" class="fas fa-plus-square text-white" style="font-size: 100px"></i>
            </div>
            <input
                id="uploadPic"
                ref="refInputEl1"
                type="file"
                class="d-none"
                accept="image/*"
                @input="makeBase64Pic($event,'base64ImageSrc')"
            >
            <input
                class="col-12 border rounded-2 p-2 chat-actions-height mt-3"
                type="text"
                name="content"
                v-model="picText"
                dir="auto"
                height="48px"
                placeholder="Your message..."
            />
          </div>
          <div class="modal-footer">
            <button type="button" @click="SendMessage" class="btn btn-primary">Upload</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {TicketCreateRequest, TicketGetAllByParentIdRequest} from "@/libs/Api/Ticket";
import Helper from "@/libs/Helper";
import baseUrl from "@/main";

export default {
  title: 'MyBit - Tickets',
  name: "Ticket",
  data() {
    return {
      Tickets:null,
      showEmojis: false,
      base64ImageSrc: null,
      content: '',
      picText:'',
      pic:null,
      baseUrl,
    }
  },
  computed:{
    user() {
      if (this.$store.getters.MyBitUser) {
        return this.$store.getters.MyBitUser.user;
      }
    },
  },
  async created(){
    await this.GetAllByParentId();
  },
  methods: {
    async GetAllByParentId(){
      let _this = this;
      Helper.openLoadingOverlay(_this);
      let  ticketGetAllByParentIdRequest = new TicketGetAllByParentIdRequest(_this)
      let data = {
        id:this.$route.params.id
      }
      ticketGetAllByParentIdRequest.setParams(data);
      await ticketGetAllByParentIdRequest.fetch((content)=>{
        _this.Tickets = content;
        Helper.closeLoadingOverlay(_this);
        _this.scrollToBottom();
      },(e)=>{
        Helper.closeLoadingOverlay(_this);
        console.log(e)
      })
    },
    async SendMessage(){
      let _this = this;
      let ticketCreateRequest = new TicketCreateRequest(_this);
      let data = {
        parentId: _this.$route.params.id,
        content: _this.content,
        type: _this.Tickets[0].type,
        title: _this.Tickets[0].title,
        createDate:new Date(Date.now()),
        userType:1,
        ticketId:0,
        isRead:false,
        fileData:null,
      }
      if (_this.pic !== null){
        data.fileData = _this.pic;
        data.content = _this.picText;
      }
      ticketCreateRequest.setRequestParamDataObj(data);
      await ticketCreateRequest.fetch(()=>{
        this.content = '';
        _this.pic = null;
        _this.picText = '';
        _this.$refs.closePicModal.click();
        _this.GetAllByParentId();
      },(e)=>{
        console.log(e)
      })
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.refChatLogPS;
        container.scrollTop = container.scrollHeight;
      })
    },
    // Upload media
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e, index) {
      const _this = this;
      let file = e.target.files[0];
      _this[index] = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.pic = result;
    },
    addEmoji(param) {
      this.message += param.emoji
    }
  },
  components: {

  },
}
</script>

<style scoped>
.chat-container {
  max-height: 100%;
  overflow-y: auto;
}

.chat {
  /*margin-top: 3vh;*/
  height: 100%;
  overflow-y: auto;
  margin-bottom: auto;
  width: 100%;
  background-color: #ffffff;
}

.card {
  min-height: 100%;
  /*border-radius: 10px !important;*/
  border-radius: 0;
  border-color: transparent !important;
}

.contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}

.msg_card_body {
  overflow-y: auto;
}

.card-header {
  /*border-radius: 15px 15px 0 0 !important;*/
  border-bottom: 0 !important;
  position: sticky !important;
  top: 0;
  width: 100%;
  z-index: 10;
}

.card-footer {
  /*border-radius: 0 0 15px 15px !important;*/
  border-top: 0 !important;
  position: sticky;
  bottom: 15px;
  width: 100%;
  background-color: white;
}

.container {
  align-content: center;
}

.search {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
}

.search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.type_msg {
  /*background-color: rgba(0, 0, 0, 0.3) !important;*/
  border-radius: 0 !important;
  /*border: 0 !important;*/
  color: black;
  height: 60px !important;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
  border-radius: 0 !important;
  resize: none !important;
}

.attach_btn {
  /*border-radius: 15px 0 0 15px !important;*/
  border-radius: 0 !important;
  padding: 22px 10px;
  /*background-color: rgba(0, 0, 0, 0.3) !important;*/
  border: 0 !important;
  color: white !important;
  cursor: pointer;
  background-color: #4a4a4a !important;
}

.send_btn {
  /*border-radius: 0 15px 15px 0 !important;*/
  padding: 21px 10px;
  border-radius: 0 !important;
  /*border: 1px solid rgba(0, 0, 0, 0.1);*/
  background-color: rgb(13, 110, 253) !important;
  /*border: 0 !important;*/
  color: white !important;
  cursor: pointer;
}

.search_btn {
  border-radius: 0 15px 15px 0 !important;
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}

.contacts {
  list-style: none;
  padding: 0;
}

.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
  transition: 150ms;
  cursor: pointer;
}

.active {
  /*background-color: rgba(0, 0, 0, 0.3);*/
}

.contacts li:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.user_img {
  height: 60px;
  width: 60px;
  border: 1.5px solid #f5f6fa;
}

.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}

.img_cont {
  position: relative;
  height: 70px;
  width: 70px;
}

.img_cont_msg {
  height: 40px;
  width: 40px;
}

.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}

.offline {
  background-color: #c23616 !important;
}

.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

.user_info span {
  font-size: 20px;
  color: black;
}

.user_info p {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
}

.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}

.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: #82ccdd;
  padding: 10px;
  position: relative;
}

.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #78e08f;
  padding: 10px;
  position: relative;
}

.msg_time {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
}

.msg_time_send {
  position: absolute;
  right: 0;
  bottom: -20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
}

.msg_head {
  /*position: relative;*/
  background-color: white;
}

#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}

.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}

.action_menu ul li i {
  padding-right: 10px;
}

.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 576px) {
  .emoji-container {
    position: absolute;
    top: -500% !important;
  }

  .contacts_card {
    /*margin-bottom: 15px !important;*/
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7F7FD5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5454b6;
}

.chevron-left {
  cursor: pointer;
}

.emoji-container {
  position: sticky;
  bottom: 0;
  left: 0;
}
.market-trade{
    /*border: none!important;*/
  border-radius: .25rem;
}
.ticket-h{
  height: 85vh;
}
.chat-background{
  height: 100%;
}
.card-body{
  min-height: 90vh;
  overflow: hidden;
}
.form-control-lg{
  background-color: transparent;
}
.dark .chat-container{
  background-color: #1e222d !important;
}
</style>