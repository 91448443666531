<template>
  <div class="market-order markets-pair-list mt15">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="all-trades-tab"
          data-bs-toggle="tab"
          data-bs-target="#all-trades"
          type="button"
          role="tab"
          aria-controls="all-trades"
          aria-selected="true"
        >
          All trades
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="my-trades-tab"
          data-bs-toggle="tab"
          data-bs-target="#my-trades"
          type="button"
          role="tab"
          aria-controls="my-trades"
          aria-selected="false"
        >
          My trades
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="order-history-tab"
          data-bs-toggle="tab"
          data-bs-target="#order-history"
          type="button"
          role="tab"
          aria-controls="order-history"
          aria-selected="false"
        >
          High trades
        </button>
      </li>
      <!-- <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="recent-trades-tab"
          data-bs-toggle="tab"
          data-bs-target="#recent-trades"
          type="button"
          role="tab"
          aria-controls="recent-trades"
          aria-selected="true"
        >
          Recent Trades
        </button>
      </li> -->
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="all-trades"
        role="tabpanel"
        aria-labelledby="all-trades-tab"
      >
        <div v-if="allTrades.length > 0" class="table-responsive">
          <table class="table">
            <thead>
              <tr class="">
                <th>Currency</th>
                <th>User Name</th>
                <th>Amount</th>
                <th>Up / Down</th>
                <th>Result</th>
                <th>Profit / Loss</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="t in allTrades" :key="t.tradeId" class="">
                <td>{{ t.market }}</td>
                <td>{{ t.userInfo.userName }}</td>
                <td>{{ t.amount }} $</td>
                <td>
                  <span
                    v-if="t.type === 1"
                    class="icon ion-md-arrow-up text-success"
                  ></span>
                  <span
                    v-if="t.type === 2"
                    class="icon ion-md-arrow-down text-danger"
                  ></span>
                </td>
                <td>
                  <span v-if="t.state === 1" class="badge bg-secondary"
                    >Pending</span
                  >
                  <span v-if="t.state === 2" class="badge bg-success">Win</span>
                  <span v-if="t.state === 3" class="badge bg-danger">Lose</span>
                </td>
                <td>
                  <span v-if="t.state === 2 && t.profit > 0" class="text-success">{{t.profit}} $</span>
                  <span v-if="t.state === 3 && t.loss > 0" class="text-danger">{{t.loss}} $</span>
                  <span v-if="t.profit === 0" class="">{{t.profit}} $</span>
                </td>
                <td>
                  {{ t.createDate.slice(0, 10) }}
                  -
                  {{ t.createDate.slice(11, 19) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span v-else class="no-data">
          <i class="icon ion-md-document"></i>
          No data
        </span>
      </div>

      <div
        class="tab-pane fade"
        id="my-trades"
        role="tabpanel"
        aria-labelledby="my-trades-tab"
      >
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr class="">
                <th class="dropdown p-relative">
                  <a 
                  class="nav-link dropdown-toggle mt-md-1"
                  href="#"
                  id="navbarDropdown10"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  >
                  Currency
                </a>
                <ul
                class="dropdown-menu dropdown-menu-end p-0"
                aria-labelledby="navbarDropdown10"
                >
                  <li class="my-0">
                    <p v-for="symbol in symbols" :key="symbol.name" @click="setNewSymbol(symbol)"
                       :class="[symbol.exchangeName === selectedSymbol ? 'text-white bg-primary' : '']"
                       class="dropdown-item cursor-pointer py-2 my-0">
                      <img v-if="symbol.icon" width="30" height="30" style="margin-right: 10px" :src="'/img/icon/' + symbol.icon" alt="">
                      {{ symbol.name }}
                    </p>
                  </li>
                </ul>
                </th>
                <th>Amount</th>
                <th>Up / Down</th>
                <th>Result</th>
                <th>Profit / Loss</th>
                <th>Date</th>
                <th>Share</th>
              </tr>
            </thead>
            <tbody v-if="myTrades.length > 0">
              <tr v-for="t in myTrades" :key="t.tradeId" class="">
                <td>{{ t.market }}</td>
                <td>{{ t.amount }} $</td>
                <td>
                  <span
                    v-if="t.type === 1"
                    class="icon ion-md-arrow-up text-success"
                  ></span>
                  <span
                    v-if="t.type === 2"
                    class="icon ion-md-arrow-down text-danger"
                  ></span>
                </td>
                <td>
                  <span v-if="t.state === 1" class="badge bg-secondary"
                    >Pending</span
                  >
                  <span v-if="t.state === 2" class="badge bg-success">Win</span>
                  <span v-if="t.state === 3" class="badge bg-danger">Lose</span>
                </td>
                <td>
                  <span v-if="t.state === 2 && t.profit > 0" class="text-success">{{t.profit}} $</span>
                  <span v-if="t.state === 3 && t.loss > 0" class="text-danger">{{t.loss}} $</span>
                  <span v-if="t.profit === 0" class="">{{t.profit}} $</span>
                </td>
                <td>
                  {{ t.createDate.slice(0, 10) }}
                  -
                  {{ t.createDate.slice(11, 19) }}
                </td>
                <td>
                  <span
                    @click="share(t)"
                    class="icon ion-md-share text-primary cursor-pointer"
                  ></span>
                </td>
              </tr>
            </tbody>
            <span v-else class="no-data">
          <i class="icon ion-md-document"></i>
          No data
        </span>
          </table>
        </div>
      
      </div>

      <div
        class="tab-pane fade"
        id="order-history"
        role="tabpanel"
        aria-labelledby="order-history-tab"
      >
      <div v-if="hightTrades.length > 0" class="table-responsive">
          <table class="table">
            <thead>
              <tr class="">
                <th>Market</th>
                <th>User Name</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="t in hightTrades" :key="t.tradeId" class="">
                <td>{{ SymbolName }}</td>
                <td>{{ t.userInfo.userName }}</td>
                <td class="text-success">{{ t.sum }} $</td>
                <!-- <td>
                  <span
                    v-if="t.type === 1"
                    class="icon ion-md-arrow-up text-success"
                  ></span>
                  <span
                    v-if="t.type === 2"
                    class="icon ion-md-arrow-down text-danger"
                  ></span>
                </td>
                <td>
                  <span v-if="t.state === 1" class="badge bg-secondary"
                    >Pending</span
                  >
                  <span v-if="t.state === 2" class="badge bg-success">Win</span>
                  <span v-if="t.state === 3" class="badge bg-danger">Lose</span>
                </td>
                <td>
                  {{t.profit}}
                </td> -->
                <!-- <td>
                  {{ t.createDate.slice(0, 10) }}
                  -
                  {{ t.createDate.slice(11, 19) }}
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <span v-else class="no-data">
          <i class="icon ion-md-document"></i>
          No data
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Helper from "@/libs/Helper";
import { TradeGetMyTradesRequest , TradeGetAllRequest , TradeHighTradesRequest} from "@/libs/Api/Trade";
import { mapGetters } from "vuex";
export default {
  name: "MarketTradesHistory",
  data() {
    return {
      allTrades: [],
      myTrades: [],
      hightTrades:[],
      symbols: [
        {
          name: 'All',
          exchangeName: '',
          icon: ''
        },
        {
          name: 'BTCUSDT',
          exchangeName: 'BTCUSDT',
          icon: '18.png'
        },
        {
          name: 'TRXUSDT',
          exchangeName: 'TRXUSDT',
          icon: '6.png'
        },
        {
          name: 'ADAUSDT',
          exchangeName: 'ADAUSDT',
          icon: '8.png'
        },
        {
          name: 'XRPUSDT',
          exchangeName: 'XRPUSDT',
          icon: 'ripple.png'
        },
        {
          name: 'DOGEUSDT',
          exchangeName: 'DOGEUSDT',
          icon: 'dodge.jpg'
        },
        {
          name: 'ETHUSDT',
          exchangeName: 'ETHUSDT',
          icon: '1.png'
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["Symbol", "SymbolName"]),
  },
  async created() {
    await this.getAllTrades();
    await this.getMyTrades('');
    await this.getHighTrades();
  },
  methods: {
    async getAllTrades() {
      let _this = this;
      // Helper.openLoadingOverlay(_this);
      let tradeGetAllRequest = new TradeGetAllRequest(_this);
      let data = {
        pageNumber: 1,
        count: 15,
        market: _this.SymbolName,
        state: "",
      };
      tradeGetAllRequest.setParams(data);
      await tradeGetAllRequest.fetch(
        function (content) {
          _this.allTrades = content.trades;
          // _this.totalCount = content.tradesCount;
          // Helper.closeLoadingOverlay(_this);
        },
        function (error) {
          console.log(error);
          // Helper.closeLoadingOverlay(_this);
        }
      );
    },
    async getMyTrades(symbol) {
      let _this = this;
      // Helper.openLoadingOverlay(_this);

      let data = {
        pageNumber: 1,
        count: 15,
        market: symbol,
        state: "",
      };

      let tradeGetMyTradesRequest = new TradeGetMyTradesRequest(_this);
      tradeGetMyTradesRequest.setParams(data);
      await tradeGetMyTradesRequest.fetch(
        function (content) {
          _this.myTrades = content.trades;
          // Helper.closeLoadingOverlay(_this);
        },
        function (error) {
          console.log(error);
          // Helper.closeLoadingOverlay(_this);
        }
      );
    },
    async getHighTrades() {
      let _this = this;
      // Helper.openLoadingOverlay(_this);

      let data = {
        count: 15,
        market: _this.SymbolName,
      };

      let tradeHighTradesRequest = new TradeHighTradesRequest(_this);
      tradeHighTradesRequest.setParams(data);
      await tradeHighTradesRequest.fetch(
        function (content) {
          _this.hightTrades = content;
          // Helper.closeLoadingOverlay(_this);
        },
        function (error) {
          console.log(error);
          // Helper.closeLoadingOverlay(_this);
        }
      );
    },
    setNewSymbol(param) {
      Helper.openLoadingOverlay(this);
      this.getMyTrades(param.exchangeName);
      Helper.closeLoadingOverlay(this);
    },
    share(trade){
      this.$emit('shareTrade',JSON.stringify(trade))

      // let shareText = 
      // `
      // Market : ${trade.market}
      // Amount : ${trade.amount} $
      // ${trade.state === 2 ? 'Profit' : trade.state === 3 ?'Loss' : ''} : ${trade.profit} $
      // Result : ${trade.state === 2 ? 'Win' : trade.state === 3 ?'Lose' : ''}
      // `;
    },
  },
};
</script>

<style scoped></style>
