/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Vue from 'vue'
import {createApp} from "vue";
import axios from "axios";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/css/ionicons.min.css";
import "./assets/scss/style.scss";
import router from "./router";
import store from "./store";
import "@/libs/Api/WebServiceRequest";
import '@/libs/vue-signalR';
import VuexPersistence from 'vuex-persist'
import titleMixin from './mixins/titleMixin'
import VueApexCharts from "vue3-apexcharts";

// Toast
import Toast, {PluginOptions, POSITION} from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options: PluginOptions = {
    // You can set your default options here
};

// Audio
import '@/libs/audio-output'

// Vuelidate

/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Helper from '@/libs/Helper';

const baseUrl = 'https://mybitapi.oregonserver.com/';
export default baseUrl;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
createApp(App)
    .use(store)
    .use(router)
    .use(Toast, {position: POSITION.TOP_LEFT, transition: "Vue-Toastification__slideBlurred"})
    .use(VueApexCharts)
    .mixin(titleMixin)
    .mount("#app");
