<template>
  <div class="vh-100 d-flex justify-content-center">
    <div class="form-access mt-10">
      <form>
        <span>Forgot password</span>
        <input
          type="email"
          @keyup.enter="validateForm"
          v-model="v$.email.$model"
          :class="[v$.email.$error ? 'border-danger' : '']"
          class="form-control"
          placeholder="Enter Your Email Address"
        />
        <button @click="validateForm" type="button" class="btn btn-primary">Send to my email</button>
        <h2>
          Remember Password?
          <router-link to="/login"> Sign in here</router-link>
        </h2>
      </form>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useVuelidate} from '@vuelidate/core';
import {required, email, minLength} from '@vuelidate/validators'
import { useToast } from "vue-toastification";
import {AccountForgotPasswordRequest} from "@/libs/Api/Account";
import Helper from "@/libs/Helper";
export default {
  title: 'MyBit - Reset',
  data(){
    return{
      toast: useToast(),
      email:'',
    }
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  methods:{
    async validateForm() {
      let _this = this;
      const result = await this.v$.$validate();

      if (!result) {
        return _this.toast.error("Please enter a correct email address.");
      }
      await _this.sendPass();
    },
    async sendPass(){
      let _this = this;
      Helper.openLoadingOverlay(_this);
      let data = {
        PhoneOrEmail:_this.email,
        type:2
      };
      let accountForgotPasswordRequest = new AccountForgotPasswordRequest(_this);
      accountForgotPasswordRequest.setParams(data);
      await accountForgotPasswordRequest.fetch(function (content){
        Helper.closeLoadingOverlay(_this);
        if (content.data.status === 6) {
           _this.toast.error("User not found!");
        } else if (content.data.status === 7) {
          _this.toast.success("Your password has been sent to your email address.");
          _this.$router.push('/login');
        }
      },function (error){
        Helper.closeLoadingOverlay(_this);
        console.log(error)
      })
    }
  },
}
</script>

