<template>
  <div
      class="tab-pane fade "
      id="Tickets"
      role="tabpanel"
      aria-labelledby="Tickets-tab"
      v-if="tickets !== null"
      >

        <div class="col-12 mx-auto card">
          <div class="card-body">
            <h3 class="card-title">Your Tickets</h3>
            <div class="wallet-history">
              <table class="table" v-if="tickets.length > 0">
                <thead>
                <tr>
                  <th>Title</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Show</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="ticket in tickets" :key="ticket.ticketId">
                  <td>{{ticket.title}}</td>
                  <td v-if="ticket.type === 1">Authentication</td>
                  <td v-if="ticket.type === 2">Support</td>
                  <td v-if="ticket.type === 3">Others</td>
                  <td v-if="ticket.isRead === true" class="text-success">Read</td>
                  <td v-if="ticket.isRead === false" class="text-danger">Unread</td>
                  <td>
                    <router-link :to="`/Tickets/`+ticket.ticketId">
                      <i class="icon ion-md-eye text-primary mb-5" style="font-size: 18px"></i>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>

              <span v-else class="no-data">
                      <i class="icon ion-md-document mx-0"></i>
                      No data
              </span>
            </div>
          </div>
        </div>

        <div class="col-12 my-4 mx-auto px-0">
          <button class="btn btn-success col-12 col-sm-2" @click="showNewTicket = !showNewTicket" type="button">+ New ticket</button>
        </div>

        <transition name="slide-up">
          <div v-if="showNewTicket" class="col-12 mx-auto card">
            <div class="card-body">
              <h4 class="card-title">Send new ticket to support</h4>
              <div class="row">
                <div class="col-md-6 my-2">
                  <label htmlFor="title">Title</label>
                  <input
                      :class="[v$.title.$error ? 'border-danger' : '']"
                      id="title"
                      type="text"
                      class="form-control"
                      v-model="v$.title.$model"
                      placeholder="Enter title of your ticket"
                  />
                </div>
                <div class="col-md-6 my-2">
                  <label htmlFor="type">Type</label>
                  <select v-model="v$.type.$model" id="type" class="form-select" :class="[v$.type.$error ? 'border-danger' : '']">
                    <option v-for="i in TicketTypesId" :key="i.value" :value="i.value">{{i.option}}</option>
                  </select>
                </div>
                <div class="col-12 my-2">
                  <label htmlFor="content">Content</label>
                  <textarea
                      id="content"
                      class="form-select"
                      :class="[v$.content.$error ? 'border-danger' : '']"
                      v-model="v$.content.$model"
                  ></textarea>
                </div>
                <div class="col-md-12 my-3">
                  <button class="btn btn-primary col-12 col-sm-2" @click="submit" type="button">Submit ticket</button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

</template>

<script>
/* eslint-disable */
import {TicketCreateRequest, TicketGetAllByParentIdRequest, TicketGetAllForCustomerRequest} from "@/libs/Api/Ticket";
import Helper from "@/libs/Helper";
import {useVuelidate} from "@vuelidate/core";
import {minLength, required} from '@vuelidate/validators'
import {useToast} from "vue-toastification";
export default {
  name: "Tickets",
  data(){
    return{
      toast: useToast(),
      tickets:null,
      showNewTicket:false,
      ticketType:0,
      content:'',
      title:'',
      type:'',
      TicketTypesId:[
        {
          option:"authentication",
          value:1
        },
        {
          option:"support",
          value:2
        },
        {
          option:"others",
          value:3
        },
      ],
    }
  },
  validations () {
    return {
      title: {
        required
      },
      content:{
        required
      },
      type:{
        required
      }
    }
  },
  async created(){
    await this.getAllTickets();
  },
  methods:{
    async submit () {
      const result = await this.v$.$validate()
      if (!result) {
        return this.toast.error("All fields are required");
      }
     await this.createTicket();
    },
    async createTicket(){
      let _this = this;
      let ticketCreateRequest = new TicketCreateRequest(_this)
      let data = {
        isDeleted:false,
        parentId:0,
        ticketId:0,
        content: _this.content,
        type: _this.type,
        title: _this.title,
        createDate:new Date(Date.now()),
      }
      ticketCreateRequest.setParams(data);
      await ticketCreateRequest.fetch(()=>{
        _this.getAllTickets();
        _this.showNewTicket = false;
        _this.content = '';
      },(e)=>{
        console.log(e)
      })
    },
    async getAllTickets(){
      let _this = this;
      Helper.openLoadingOverlay(_this);

      let data={
        pageNumber:1,
        count:10
      }

      let ticketGetAllForCustomerRequest = new TicketGetAllForCustomerRequest(_this);
      ticketGetAllForCustomerRequest.setParams(data);
      await ticketGetAllForCustomerRequest.fetch(function (content){
        Helper.closeLoadingOverlay(_this);
        _this.tickets = content.tickets;
      },function (error){
        Helper.closeLoadingOverlay(_this);
        console.log(error)
      })
    },
  },
  computed:{
    currentDate(){
      let today = new Date();
      let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return  date+' '+time;
    },
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
}
</script>

<style scoped>

</style>