<template>
  <div class="main-chart mb15">
    <VueTradingView
      :options="options"
      :key="options.symbol"
    />
  </div>
</template>

<script>
import VueTradingView from "vue-trading-view/src/vue-trading-view.vue";
import { mapGetters } from "vuex";

export default {
  created(){
     this.options.symbol = this.$store.state.Symbol;
  },
  computed:{
    ...mapGetters(["Symbol"])
  },
  watch:{
    Symbol: function (val,oldVal) {
      this.options.symbol = val;
    },
  },
  data(){
    return{
      options:{
        symbol: '',
        theme: 'dark',
        width: '100%',
        height: '100%',
        allow_symbol_change: true,
        hide_side_toolbar: false,
        details: false,
      },
    }
  },
  components: {
    VueTradingView,
  },
};
</script>