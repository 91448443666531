<template>
  <div
      v-if="userData !== null"
      class="tab-pane fade "
      id="Profile"
      role="tabpanel"
      aria-labelledby="Profile-tab"
  >
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Your Information</h5>
        <div class="settings-profile">
          <form>
            <img class="avatar" :src="base64ImageSrc ? base64ImageSrc : defaultPic" alt="avatar" />
            <div class="custom-file">
              <input  ref="refInputEl"
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      @input="makeBase64Pic" class="form-control" />
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <label htmlFor="formFirst">First name</label>
                <input
                    v-model="userData.name"
                    id="formFirst"
                    type="text"
                    class="form-control"
                    placeholder="First name"
                />
              </div>
              <div class="col-md-6">
                <label htmlFor="formLast">Last name</label>
                <input
                    v-model="userData.familyName"
                    id="formLast"
                    type="text"
                    class="form-control"
                    placeholder="Last name"
                />
              </div>
              <div class="col-md-6">
                <label htmlFor="nickname">Username</label>
                <input
                    v-model="userData.userName"
                    disabled
                    id="nickname"
                    type="text"
                    class="form-control"
                    placeholder="Enter your nickname"
                />
              </div>
              <div class="col-md-6">
                <label htmlFor="emailAddress">Email</label>
                <input
                    v-model="userData.email"
                    disabled
                    id="emailAddress"
                    type="text"
                    class="form-control"
                    placeholder="Enter your email"
                />
              </div>
              <div class="col-md-6">
                <label htmlFor="currentPass">
                  Current password
                </label>
                <input
                    v-model="userData.currentPassword"
                    id="currentPass"
                    type="text"
                    class="form-control"
                    placeholder="Enter your password"
                />
              </div>
              <div class="col-md-6">
                <label htmlFor="newPass">New password</label>
                <input
                    v-model="userData.password"
                    id="newPass"
                    type="text"
                    class="form-control"
                    placeholder="Enter new password"
                />
              </div>
              <div class="col-md-12">
                <button class="btn btn-primary col-12 col-sm-1" @click="submitEdit" type="button">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {UsersGetRequest, UsersUpdateRequest} from "@/libs/Api/Users";
import Helper from "@/libs/Helper";
import baseUrl from "@/main";
import {useToast} from "vue-toastification";

export default {
  name: "Profile",
  data(){
    return {
      userId: this.$store.getters.MyBitUser.user.userId,
      userData:null,
      base64ImageSrc: null,
      defaultPic:"/img/avatar.svg",
      passwordRepeat: '',
      baseUrl,
      toast: useToast(),
    }
  },
  computed:{
    userAvatar() {
      return this.base64ImageSrc
          ? this.base64ImageSrc
          : "/img/avatar.svg";
    },
  },
  async created() {
    await this.getUser();
  },
  methods:{
    async getUser() {
      let _this = this;

      Helper.openLoadingOverlay(_this)

      let usersGetRequest = new UsersGetRequest(_this);
      usersGetRequest.setUserId(_this.userId);
      await usersGetRequest.fetch(function (content) {
        Helper.closeLoadingOverlay(_this)
        _this.userData = content;
        if (_this.userData.selfieFileData !== null) {
          _this.base64ImageSrc = _this.baseUrl + _this.userData.selfieFileData;
        }
        _this.userData.password = '';
        _this.userData.currentPassword = '';
      }, function (error) {
        console.log(error)
      })
    },
    async submitEdit() {
      let _this = this;

        Helper.openLoadingOverlay(_this)
        let usersUpdateByAdminRequest = new UsersUpdateRequest(_this);
        usersUpdateByAdminRequest.setParams(_this.userData);
        await usersUpdateByAdminRequest.fetch(function (content) {
          Helper.closeLoadingOverlay(_this);
          if(content.errors){
            return _this.toast.error("Fill required fields");
          }
          if (content === 2){
            return _this.toast.error("Your previous password is not correct");
          }
          _this.toast.success('Your changes submitted');
          _this.getUser();
        }, function (error) {
          Helper.closeLoadingOverlay(_this);
          console.log(error);
        })
      // else {
      //   return _this.toast.error("Your current password field is required");
      // }
    },
    async makeBase64Pic(e){
      let file = e.target.files[0];
      this.base64ImageSrc = URL.createObjectURL(file);
      const result = await Helper.readAsDataURL(file);
      this.userData.selfieFileData = result;
    },
  },
}
</script>

<style scoped>
.avatar{
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 50%;
}
.profile-pic-holder{
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.edit-pic-container{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,40%);
  width: 5rem;
  height: 4rem;
  cursor: pointer;
  color: #4a4a4a;
}
.edit-pic-container:hover{
  color: #0a98dc;
}
</style>