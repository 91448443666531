<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <MarketCarousel />
      </div>
    </div>
    <MarketsList />
  </div>
</template>

<script>
import MarketCarousel from "../components/MarketCarousel.vue"
import MarketsList from "../components/MarketsList.vue"
export default {
  title: 'MyBit - Markets',
  components: {
    MarketCarousel,
    MarketsList,
  },
}
</script>
