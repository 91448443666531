<template>
  <div
      class="tab-pane fade"
      id="Settings"
      role="tabpanel"
      aria-labelledby="Settings-tab"
  >
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Notifications</h5>
        <div class="settings-notification">
          <ul>
            <li>
              <div class="notification-info">
                <p>Update price</p>
                <span> Get the update price in your dashboard </span>
              </div>
              <div class="custom-control form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="notification1"
                />
                <label
                    class="form-check-label"
                    htmlFor="notification1"
                ></label>
              </div>
            </li>
            <li>
              <div class="notification-info">
                <p>2FA</p>
                <span>
                            Unable two factor authentication service
                          </span>
              </div>
              <div class="custom-control form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="notification2"
                    checked
                />
                <label
                    class="form-check-label"
                    htmlFor="notification2"
                ></label>
              </div>
            </li>
            <li>
              <div class="notification-info">
                <p>Latest news</p>
                <span>Get the latest news in your mail</span>
              </div>
              <div class="custom-control form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="notification3"
                />
                <label
                    class="form-check-label"
                    htmlFor="notification3"
                ></label>
              </div>
            </li>
            <li>
              <div class="notification-info">
                <p>Email Service</p>
                <span>Get security code in your mail</span>
              </div>
              <div class="custom-control form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="notification4"
                    checked
                />
                <label
                    class="form-check-label"
                    htmlFor="notification4"
                ></label>
              </div>
            </li>
            <li>
              <div class="notification-info">
                <p>Phone Notify</p>
                <span>
                            Get transition notification in your phone
                          </span>
              </div>
              <div class="custom-control form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="notification5"
                    checked
                />
                <label
                    class="form-check-label"
                    htmlFor="notification5"
                ></label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card settings-profile">
      <div class="card-body">
        <h5 class="card-title">Create API Key</h5>
        <div class="row">
          <div class="col-md-6">
            <label htmlFor="generateKey"> Generate key name </label>
            <input
                :class="[v$.key.$error ? 'border-danger' : '']"
                id="generateKey"
                type="text"
                class="form-control"
                v-model="v$.key.$model"
                placeholder="Enter your key name"
            />
          </div>
          <div class="col-md-6">
            <label htmlFor="rewritePassword">
              Confirm password
            </label>
            <input
                :class="[v$.password.$error ? 'border-danger' : '']"
                id="rewritePassword"
                type="password"
                class="form-control"
                v-model="v$.password.$model"
                placeholder="Confirm your password"
            />
          </div>
          <div class="col-md-12">
            <input type="submit" value="Set password" @click="submit" />
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Your API Keys</h5>
        <div class="wallet-history">
          <table class="table">
            <thead>
            <tr>
              <th>No.</th>
              <th>Key</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>1</td>
              <td>zRmWVcrAZ1C0RZkFMu7K5v0KWC9jUJLt</td>
              <td>
                <div class="custom-control form-check">
                  <input
                      type="checkbox"
                      class="form-check-input"
                      id="apiStatus1"
                      checked
                  />
                  <label
                      class="form-check-label"
                      htmlFor="apiStatus1"
                  ></label>
                </div>
              </td>
              <td>
                <i class="icon ion-md-trash"></i>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Rv5dgnKdmVPyHwxeExBYz8uFwYQz3Jvg</td>
              <td>
                <div class="custom-control form-check">
                  <input
                      type="checkbox"
                      class="form-check-input"
                      id="apiStatus2"
                  />
                  <label
                      class="form-check-label"
                      htmlFor="apiStatus2"
                  ></label>
                </div>
              </td>
              <td>
                <i class="icon ion-md-trash"></i>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>VxEYIs1HwgmtKTUMA4aknjSEjjePZIWu</td>
              <td>
                <div class="custom-control form-check">
                  <input
                      type="checkbox"
                      class="form-check-input"
                      id="apiStatus3"
                  />
                  <label
                      class="form-check-label"
                      htmlFor="apiStatus3"
                  ></label>
                </div>
              </td>
              <td>
                <i class="icon ion-md-trash"></i>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>M01DueJ4x3awI1SSLGT3CP1EeLSnqt8o</td>
              <td>
                <div class="custom-control form-check">
                  <input
                      type="checkbox"
                      class="form-check-input"
                      id="apiStatus4"
                  />
                  <label
                      class="form-check-label"
                      htmlFor="apiStatus4"
                  ></label>
                </div>
              </td>
              <td>
                <i class="icon ion-md-trash"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators'


export default {
  name: "Setting",
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  data(){
    return{
      key:'',
      password:'',
    }
  },
  validations () {
    return {
      key: {
        required
      },
      password:{
        required ,
        minLength: minLength(4),
      }
    }
  },
  methods:{
    async submit () {
      const result = await this.v$.$validate()
      if (!result) {
        // notify user form is invalid
        return console.log('Invalid')
      }
      // perform async actions
      return console.log('Valid')
    }
  },
}
</script>

<style scoped>

</style>