<template>
  <div class="market-trade overflow-hidden h-100">
    <!-- Media Modal -->
    <teleport to="body">
      <div
        class="modal fade"
        id="mediaModal"
        tabindex="-1"
        aria-labelledby="mediaModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="mediaModalLabel">Upload media</h5>
              <button
                type="button"
                ref="closePicModal"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                @click="$refs.refInputEl1.click()"
                :style="`background-image : url(${base64ImageSrc})`"
                class="upload-box rounded-3 bg-secondary cursor-pointer d-flex justify-content-center align-items-center"
              >
                <i
                  v-if="base64ImageSrc === null"
                  class="fas fa-plus-square text-white"
                  style="font-size: 100px"
                ></i>
              </div>
              <input
                id="uploadPic"
                ref="refInputEl1"
                type="file"
                class="d-none"
                accept="image/*"
                @input="makeBase64Pic($event, 'base64ImageSrc')"
              />
              <input
                class="col-12 border rounded-2 p-2 chat-actions-height mt-3"
                type="text"
                name="content"
                v-model="picText"
                dir="auto"
                height="48px"
                placeholder="Your message..."
              />
            </div>
            <div class="modal-footer">
              <!--            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
              <button
                type="button"
                @click="createMessageData(1)"
                class="btn btn-primary"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

    <!-- Voice Modal -->
    <teleport to="body">
      <div
        class="modal fade"
        id="voiceModal"
        tabindex="-1"
        aria-labelledby="voiceModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="voiceModalLabel">Upload voice</h5>
              <button
                type="button"
                class="btn-close"
                ref="closeVoiceModal"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <AudioRecorder @getAudioBlob="SetAudioBlob" ref="Recorder" />

              <div
                style="height: 10rem"
                class="w-100 d-flex flex-column align-items-center justify-content-center rounded-6"
              >
                <button
                  v-if="!IsRecording"
                  class="btn btn-primary"
                  type="button"
                  @click.stop="StartRecording"
                >
                  <i class="fas fa-play"></i>
                </button>
                <div v-else @click="StopRecording" class="blob red"></div>
                <div class="flex items-center flex-row-reverse gap-3 my-2">
                  <small>{{ Seconds }}</small>
                  :
                  <small>{{ Minutes }}</small>
                  :
                  <small>{{ Hours }}</small>
                </div>
                <audio
                  v-if="VoiceMessage !== ''"
                  :src="GenerateAudioSrc"
                  controls
                ></audio>
              </div>
            </div>
            <div class="modal-footer">
              <button
                @click="createMessageData(2)"
                type="button"
                class="btn btn-primary"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

    <!--  ShowPic Modal  -->
    <teleport to="body">
      <div
        class="modal fade"
        style="margin-top: 100px"
        id="ShowPicModal"
        tabindex="-1"
        aria-labelledby="ShowPicModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div
                :style="`background-image : url(${selectedPic})`"
                class="big-pic rounded-3 bg-white cursor-pointer d-flex justify-content-center align-items-center"
              ></div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                ref="closePicModal"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </teleport>

    <ul v-if="!showChatLog" class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="limit-tab"
          data-bs-toggle="tab"
          data-bs-target="#limit"
          type="button"
          role="tab"
          aria-controls="limit"
          aria-selected="true"
        >
        Chats
        <!-- <i class="icon ion-ios-chatboxes" :class="[showChat ? 'text-primary' : 'text-danger']" style="font-size: 20px;"></i>
        <small class="ms-1">{{ showChat ? 'Chats' : 'Show Chats' }}</small> -->
        </button>
      </li>
    </ul>

    <div
      class="tab-content h-100 px-0"
      style="overflow-x: hidden"
      id="myTabContent"
    >
      <div
        class="tab-pane fade show active h-100"
        id="limit"
        role="tabpanel"
        aria-labelledby="limit-tab"
      >
        <div
          v-if="showOverlay"
          style="min-height:75vh"
          class="chat-overlay w-100 h-100 d-flex justify-content-center align-items-center"
        >
          <div
            class="spinner-border"
            style="width: 4rem; height: 4rem; color: white"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <section class="px-0 h-100">
          <div class="container px-0">
            <div class="row d-flex justify-content-center px-0">
              <div class="col-12">
                <div
                  class="card w-100 bg-transparent position-relative"
                  id="chat2"
                >
                  <div
                    class="card-body chat-background"
                    data-mdb-perfect-scrollbar="true"
                    ref="refChatLogPS"
                  >
                    <template v-for="item in messages" :key="item.MessageId">
                      <div
                        v-if="item.CreatorUserId !== user.userId"
                        class="d-flex flex-row justify-content-start mb-4"
                      >
                        <div
                          style="width: 60px"
                          class="ShortDescriptionDiv text-center"
                        >
                          <img
                            @click="
                              selectedPic =
                                baseUrl + 'media/gallery/profile/' + item.Photo
                            "
                            :src="[
                              item.Photo
                                ? baseUrl +
                                  'media/gallery/profile/' +
                                  item.Photo
                                : '/img/avatar.svg',
                            ]"
                            alt="avatar 1"
                            class="rounded-circle mx-auto cursor-pointer"
                            style="width: 45px; height: 45px"
                          />
                          <small
                            class="small text-muted d-block mt-1 ShortDescriptionIndex mx-auto"
                            :title="item.UserName"
                            >{{ item.UserName }}</small
                          >
                        </div>
                        <div>
                          <!-- <audio
                            v-if="
                              item.ChatMedia &&
                              item.ChatMedia !== null &&
                              item.ChatMedia.includes('mp3')
                            "
                            :src="baseUrlForMedia + item.ChatMedia"
                            controls
                            class="w-100 rounded"
                          ></audio> -->
                          <div v-if="item.Subject === 'Trade Sharing'"
                            :class="[JSON.parse(item.MessageBody).state === 2 ? 'bg-success' : JSON.parse(item.MessageBody).state === 3 ? 'bg-danger' : '']"
                            class="card small p-3 me-3 mb-1 text-white rounded-3 my-chat-log"
                            style="font-size: 14px;min-height: auto;!important"
                          >
                          <div class="card-header mb-0 bg-transparent" style="font-size: 17px; font-weight: 900;">Trade Result</div>
                            <hr class="w-100 text-white my-0 border">
                            <p class="text-white mt-3">Market : {{ JSON.parse(item.MessageBody).market }}</p>
                            <p class="text-white">Amount : {{ JSON.parse(item.MessageBody).amount }} $</p>
                            <p class="text-white"> 
                              {{ JSON.parse(item.MessageBody).state === 2 ? 'Profit' : JSON.parse(item.MessageBody).state === 3 ? 'Loss' : '' }}
                              : 
                              {{ JSON.parse(item.MessageBody).state === 2 ? JSON.parse(item.MessageBody).profit : JSON.parse(item.MessageBody).state === 3 ? JSON.parse(item.MessageBody).loss : '' }} $ 
                              </p>
                            <p class="text-white">Result : {{ JSON.parse(item.MessageBody).state === 2 ? 'Win' : JSON.parse(item.MessageBody).state === 3 ? 'Lose' : '' }}</p>
                          </div>
                          <div
                            v-else
                            class="small p-2 ms-3 mb-1 text-white rounded-3"
                            style="font-size: 14px; background-color: #f5f6f7"
                          >
                            <!-- <div
                              v-if="
                                item.ChatMedia &&
                                item.ChatMedia !== null &&
                                item.ChatMedia.includes('jpg')
                              "
                              @click="
                                selectedPic = baseUrlForMedia + item.ChatMedia
                              "
                              data-bs-toggle="modal"
                              data-bs-target="#ShowPicModal"
                              :style="`background-image : url(${
                                baseUrlForMedia + item.ChatMedia
                              })`"
                              class="chat-pic rounded object-cover object-center"
                            ></div> -->
                            <p>{{ item.MessageBody }}</p>
                          </div>
                          <p
                            class="small ms-3 mb-3 rounded-3 text-muted"
                            style="font-size: 10px"
                          >
                            {{ item.CreateDate.slice(0, 10) }}
                            -
                            {{ item.CreateDate.slice(11, 16) }}
                          </p>
                        </div>
                      </div>

                      <div v-else class="d-flex flex-row justify-content-end">
                        <div>
                          <!-- <audio
                            v-if="
                              item.ChatMedia &&
                              item.ChatMedia !== null &&
                              item.ChatMedia.includes('mp3')
                            "
                            :src="baseUrlForMedia + item.ChatMedia"
                            controls
                            class="w-100 rounded"
                          ></audio> -->
                          <div v-if="item.Subject === 'Trade Sharing'"
                            :class="[JSON.parse(item.MessageBody).state === 2 ? 'bg-success' : JSON.parse(item.MessageBody).state === 3 ? 'bg-danger' : '']"
                            class="card small p-3 me-3 mb-1 text-white rounded-3 my-chat-log"
                            style="font-size: 14px;min-height: auto;!important"
                          >
                          <div class="card-header mb-0 bg-transparent" style="font-size: 17px;font-weight: 900;">Trade Result</div>
                          <hr class="w-100 text-white my-0 border">
                            <p class="text-white mt-3">Market : {{ JSON.parse(item.MessageBody).market }}</p>
                            <p class="text-white">Amount : {{ JSON.parse(item.MessageBody).amount }} $</p>
                            <p class="text-white"> 
                              {{ JSON.parse(item.MessageBody).state === 2 ? 'Profit' : JSON.parse(item.MessageBody).state === 3 ? 'Loss' : '' }}
                              : 
                              {{ JSON.parse(item.MessageBody).state === 2 ? JSON.parse(item.MessageBody).profit : JSON.parse(item.MessageBody).state === 3 ? JSON.parse(item.MessageBody).loss : '' }} $ 
                              </p>
                            <p class="text-white">Result : {{ JSON.parse(item.MessageBody).state === 2 ? 'Win' : JSON.parse(item.MessageBody).state === 3 ? 'Lose' : '' }}</p>
                          </div>
                          <div
                            v-else
                            class="small p-2 me-3 mb-1 text-white rounded-3 bg-primary my-chat-log"
                            style="font-size: 14px"
                          >
                            <!-- <div
                              v-if="
                                item.ChatMedia &&
                                item.ChatMedia !== null &&
                                item.ChatMedia.includes('jpg')
                              "
                              @click="
                                selectedPic = baseUrlForMedia + item.ChatMedia
                              "
                              data-bs-toggle="modal"
                              data-bs-target="#ShowPicModal"
                              :style="`background-image : url(${
                                baseUrlForMedia + item.ChatMedia
                              })`"
                              class="chat-pic rounded object-cover object-center"
                            ></div> -->
                            <p class="text-white">{{ item.MessageBody }}</p>
                          </div>
                          <p
                            class="small me-3 mb-3 rounded-3 text-muted d-flex justify-content-end"
                            style="font-size: 10px"
                          >
                            {{ item.CreateDate.slice(0, 10) }}
                            -
                            {{ item.CreateDate.slice(11, 16) }}
                          </p>
                        </div>
                        <div
                          style="width: 60px"
                          class="ShortDescriptionDiv text-center"
                        >
                          <img
                            :src="[
                              item.Photo
                                ? baseUrl +
                                  'media/gallery/profile/' +
                                  item.Photo
                                : '/img/avatar.svg',
                            ]"
                            alt="avatar 1"
                            class="rounded-circle mx-auto cursor-pointer"
                            style="width: 45px; height: 45px"
                          />
                          <small
                            class="small text-muted d-block mt-1 ShortDescriptionIndex mx-auto"
                            :title="item.UserName"
                            >{{ item.UserName }}</small
                          >
                        </div>
                      </div>
                    </template>

                    <transition name="slide-up">
                      <EmojiPicker
                        class="emoji-container"
                        @SendEmoji="addEmoji"
                        v-if="showEmojis"
                      />
                    </transition>
                  </div>
                  <!--     Footer    -->
                  <div
                    class="card-footer chat-footer text-muted d-flex justify-content-start align-items-center p-3"
                  >
                    <span
                      class="chat-footer-buttons me-3 text-sm cursor-pointer"
                      @click="showEmojis = !showEmojis"
                      ><i class="fas fa-smile"></i
                    ></span>
                    <textarea
                      type="text"
                      v-model="content"
                      class="form-control form-control-lg chat-text-area"
                      id="exampleFormControlInput1"
                      placeholder="Your message ..."
                      rows="1"
                    ></textarea>
                    <!-- <span
                      class="chat-footer-buttons ms-3 text-sm cursor-pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#voiceModal"
                      ><i class="fas fa-microphone"></i
                    ></span> -->
                    <!-- <span
                      class="chat-footer-buttons ms-3 text-sm cursor-pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#mediaModal"
                      ><i class="fas fa-paperclip"></i
                    ></span> -->
                    <button type="button"
                      @click="createMessageData(0)"
                      :disabled="content.length < 1"
                      class="chat-footer-buttons ms-1 text-sm text-primary cursor-pointer btn border-0"
                      ><i class="fas fa-paper-plane"></i
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import Chat from "@/views/Chat";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon";
import EmojiPicker from "@/components/chat/EmojiPicker";
import {
  MessageGetMybitConversationRequest,
  MessageSendRequest,
} from "@/libs/Api/Message";
import Helper from "@/libs/Helper";
import AudioRecorder from "@/components/chat/AudioRecorder";
import baseUrl from "@/main";
import { mapGetters } from "vuex";

export default {
  name: "ChatComponent",
  data() {
    return {
      messages: null,
      baseUrl,
      baseUrlForMedia:
        "https://mybitapi.oregonserver.com/media/gallery/chatMedia/",
      showChatLog: false,
      showEmojis: false,
      showOverlay: false,
      content: "",
      messageData: null,
      pic: null,
      picText: "",
      showVoiceSubmit: true,
      base64ImageSrc: null,
      showUploadModal: false,
      showVoiceModal: false,
      selectedPic: "",

      // Voice variables
      IsRecording: false,
      Hours: 0,
      Minutes: 0,
      Seconds: 0,
      AudioStopWatch: null,
      VoiceMessage: "",
      fileData: {
        base64: "",
        baseImgUrl: "",
        priority: 0,
      },
    };
  },
  watch: {
    SocketId : {
      immediate : true,
      handler(val,ov){
      this.getConversation();
      this.$store.commit("SetDefaultSocketId");
      }
    },
  },
  computed: {
    SocketId(){
      if (this.$store.getters.SocketId) {
        return this.$store.getters.SocketId;
      }
    },
    user() {
      if (this.$store.getters.MyBitUser) {
        return this.$store.getters.MyBitUser.user;
      }
    },
    GenerateAudioSrc() {
      if (this.VoiceMessage) {
        return URL.createObjectURL(this.VoiceMessage);
      }
    },
  },
  async created() {
    await this.getConversation();
  },
  methods: {
    createMessageData(priority) {
      let _this = this;

      if (priority === 0) {
        _this.messageData = {
          messageId: 0,
          isDeleted: false,
          subject:
            _this.content.length > 20
              ? _this.content.substr(0, 20)
              : _this.content,
          messageBody: _this.content,
          parentMessageId: null,
          recipientUserId: null,
          recipientGroupId: 1,
          isForwarded: false,
          createDate: new Date(Date.now()),
          userId: 0,
        };
        _this.SendMessage();
      }

      if (priority === 1) {
        _this.messageData = {
          messageId: 0,
          isDeleted: false,
          subject:
            _this.content.length > 20
              ? _this.content.substr(0, 20)
              : _this.content,
          messageBody: _this.picText,
          parentMessageId: null,
          recipientUserId: null,
          recipientGroupId: 1,
          isForwarded: false,
          createDate: new Date(Date.now()),
          userId: 0,
          fileData: {
            base64: _this.pic,
            priority: 1,
          },
          mediaUrl: "",
        };
        // Helper.openLoadingOverlay(_this);
        _this.SendMessage();
        // Helper.closeLoadingOverlay(_this);
      }

      if (priority === 2) {
        _this.messageData = {
          messageId: 0,
          isDeleted: false,
          subject:
            _this.content.length > 20
              ? _this.content.substr(0, 20)
              : _this.content,
          messageBody: "",
          parentMessageId: null,
          recipientUserId: null,
          recipientGroupId: 1,
          isForwarded: false,
          createDate: new Date(Date.now()),
          userId: 0,
          fileData: {
            base64: _this.fileData.base64,
            priority: 2,
          },
          mediaUrl: "",
        };
        // Helper.openLoadingOverlay(_this);
        _this.SendMessage();
        // Helper.closeLoadingOverlay(_this);
      }
    },
    async SendMessage() {
      let _this = this;

      let messageSendRequest = new MessageSendRequest(_this);
      messageSendRequest.setParams(_this.messageData);
      await messageSendRequest.fetch(
        function (content) {
          _this.content = "";
          _this.pic = null;
          _this.picText = "";
          _this.fileData.base64 = null;
          _this.VoiceMessage = "";
          _this.showEmojis = false;
          _this.$refs.closePicModal.click();
          _this.$refs.closeVoiceModal.click();
          _this.getConversation();
        },
        function (error) {
          console.log(error);
        }
      );
    },
    shareTrade(shareText){
      let _this = this;
      console.log(shareText)
      _this.messageData = {
          messageId: 0,
          isDeleted: false,
          subject:'Trade Sharing',
          messageBody: shareText,
          parentMessageId: null,
          recipientUserId: null,
          recipientGroupId: 1,
          isForwarded: false,
          createDate: new Date(Date.now()),
          userId: 0,
        };
        _this.SendMessage();
    },
    // Voice Message Methods
    StopRecording() {
      clearInterval(this.AudioStopWatch);
      this.IsRecording = false;
      this.Seconds = 0;
      this.Hours = 0;
      this.Minutes = 0;
      this.$refs.Recorder.stopRecording();
      this.showVoiceSubmit = false;
      // this.$refs.AudioR.click();
    },
    SetAudioBlob(blob) {
      this.VoiceMessage = blob;
      let that = this;
      const reader = new FileReader();
      reader.onload = (function (theFile) {
        return function () {
          const binaryData = reader.result;
          that.fileData.base64 = window.btoa(binaryData);
          that.fileData.priority = 2;
        };
      })(blob);
      reader.readAsBinaryString(blob);
    },
    StartRecording() {
      this.IsRecording = true;
      if (this.IsRecording) {
        this.AudioStopWatch = setInterval(() => {
          this.Seconds++;
          if (this.Seconds === 59) {
            this.Seconds = 0;
            this.Minutes++;
            if (this.Minutes === 59) {
              this.Hours++;
            }
          }
        }, 1000);
        this.$refs.Recorder.startRecording();
        // this.$refs.AudioR.click();
      }
    },
    // Upload media
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        };
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e, index) {
      const _this = this;
      let file = e.target.files[0];
      _this[index] = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.pic = result;
    },
    closeUploadModal() {
      this.pic = null;
      this.picText = null;
      this.base64ImageSrc = null;
      this.showUploadModal = false;
    },
    async getConversation() {
      let _this = this;
      _this.showOverlay = true;
      let data = {
        groupId: 1,
      };
      let messageGetMybitConversationRequest =
        new MessageGetMybitConversationRequest(_this);
      messageGetMybitConversationRequest.setParam(data);
      await messageGetMybitConversationRequest.fetch(
        function (content) {
          _this.messages = [];
          content.sort(function (a, b) {
            let key1 = a.CreateDate;
            let key2 = b.CreateDate;
            if (key1 < key2) {
              return -1;
            } else if (key1 === key2) {
              return 0;
            } else {
              return 1;
            }
          });
          content.forEach((el) => {
            _this.messages.push(el);
          });
          _this.showOverlay = false;
          _this.scrollToBottom();
        },
        function (error) {
          _this.showOverlay = false;
          console.log(error);
        }
      );
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.refChatLogPS;
        container.scrollTop = container.scrollHeight;
      });
    },
    addEmoji(param) {
      this.content += param.emoji;
    },
  },
  // props: {
  //   showChat: {
  //     type: Boolean,
  //   },
  // },
  components: {
    AudioRecorder,
    ChevronLeftIcon,
    EmojiPicker,
    // Chat
  },
};
</script>

<style scoped>
.chat-container {
  max-height: 100%;
  overflow-y: auto;
}

.chat {
  /*margin-top: 3vh;*/
  height: 100%;
  overflow-y: auto;
  margin-bottom: auto;
  width: 100%;
  background-color: #ffffff;
}

.card {
  min-height: 100%;
  /*border-radius: 10px !important;*/
  border-radius: 0;
  border-color: transparent !important;
}

.contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}

.msg_card_body {
  overflow-y: auto;
}

.card-header {
  /*border-radius: 15px 15px 0 0 !important;*/
  border-bottom: 0 !important;
  position: sticky !important;
  top: 0;
  width: 100%;
  z-index: 10;
}

.card-footer {
  /*border-radius: 0 0 15px 15px !important;*/
  border-top: 0 !important;
  position: sticky;
  bottom: -100px;
  width: 100%;
  background-color: transparent;
}

.container {
  align-content: center;
}

.search {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
}

.search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.type_msg {
  /*background-color: rgba(0, 0, 0, 0.3) !important;*/
  border-radius: 0 !important;
  /*border: 0 !important;*/
  color: black;
  height: 60px !important;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
  border-radius: 0 !important;
  resize: none !important;
}

.attach_btn {
  /*border-radius: 15px 0 0 15px !important;*/
  border-radius: 0 !important;
  padding: 22px 10px;
  /*background-color: rgba(0, 0, 0, 0.3) !important;*/
  border: 0 !important;
  color: white !important;
  cursor: pointer;
  background-color: #4a4a4a !important;
}

.send_btn {
  /*border-radius: 0 15px 15px 0 !important;*/
  padding: 21px 10px;
  border-radius: 0 !important;
  /*border: 1px solid rgba(0, 0, 0, 0.1);*/
  background-color: rgb(13, 110, 253) !important;
  /*border: 0 !important;*/
  color: white !important;
  cursor: pointer;
}

.search_btn {
  border-radius: 0 15px 15px 0 !important;
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}

.contacts {
  list-style: none;
  padding: 0;
}

.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
  transition: 150ms;
  cursor: pointer;
}

.active {
  /*background-color: rgba(0, 0, 0, 0.3);*/
}

.contacts li:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.user_img {
  height: 60px;
  width: 60px;
  border: 1.5px solid #f5f6fa;
}

.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}

.img_cont {
  position: relative;
  height: 70px;
  width: 70px;
}

.img_cont_msg {
  height: 40px;
  width: 40px;
}

.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}

.offline {
  background-color: #c23616 !important;
}

.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

.user_info span {
  font-size: 20px;
  color: black;
}

.user_info p {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
}

.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}

.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: #82ccdd;
  padding: 10px;
  position: relative;
}

.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #78e08f;
  padding: 10px;
  position: relative;
}

.msg_time {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
}

.msg_time_send {
  position: absolute;
  right: 0;
  bottom: -20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
}

.msg_head {
  /*position: relative;*/
  background-color: white;
}

#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}

.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}

.action_menu ul li i {
  padding-right: 10px;
}

.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 576px) {
  .emoji-container {
    position: absolute;
    top: -500% !important;
  }

  .contacts_card {
    /*margin-bottom: 15px !important;*/
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7f7fd5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5454b6;
}

.chevron-left {
  cursor: pointer;
}

.emoji-container {
  position: sticky;
  bottom: 0;
  left: 0;
}

.big-pic {
  width: 100%;
  min-height: 30rem;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  background-size: contain;
}
</style>
