<template>

  <div class="market-history market-order markets-pair-list mt15" style="min-height: auto;!important">

    <div class="tab-content" id="myTabContent">
      <div
          class="tab-pane show active py-3"
          id="up"
          role="tabpanel"
          aria-labelledby="up-tab"
      >

       <div class="container-fluid">
         <div class="row">
             <div class="d-flex flex-row justify-content-center col-md-3 px-md-3">
               <div class="input-group-append me-1">
                 <span class="input-group-text">Amount :</span>
               </div>
               <input
                   type="number"
                   @keypress="isNumber($event)"
                   v-model="v$.amount.$model"
                   :class="[v$.amount.$error ? 'border-danger text-danger' : '']"
                   class="form-control rounded me-1 ms-1"
                   :placeholder="credit"
                   required
               />
             </div>
   
             <div class="col-md-3 d-flex flex-row justify-content-between mt-3 mt-md-0">
               <button type="button" @click="setDouble" class="btn btn-success mt-0 me-2 px-xxl-4">*2</button>
               <button type="button" @click="setHalf" class="btn btn-danger mt-0 me-2    px-xxl-4">/2</button>
               <button type="button" @click="setMin" class="btn btn-warning mt-0 me-2    px-xxl-4">Min</button>
               <button type="button" @click="setMax" class="btn btn-primary mt-0 me-2    px-xxl-4">Max</button>
             </div>
          </div>

          <div v-if="tradeType === null" class="row mt-2 mt-md-4">
            <div class="col-6 mt-3 mt-md-0 d-flex flex-row justify-content-end">
                <button :disabled="!permission" type="button" @click="setType(1)" class="trade-button-green btn buy set-order mt-0 me-2">
                  <span class="d-flex justify-content-center align-items-center">
                    <span class="ion-md-arrow-up me-2"></span>
                    Green
                  </span>
                </button>
            </div>
            <div class="col-6 mt-3 mt-md-0 d-flex flex-row justify-content-end">
                <button :disabled="!permission" type="button" @click="setType(2)" class="trade-button-red btn sell set-order mt-0 me-2">
                  <span class="d-flex justify-content-center align-items-center">
                    <span class="ion-md-arrow-down me-2"></span>
                    Red
                  </span>
                </button>
            </div>
          </div>
          <div v-else class="row mt-2 mt-md-4">
            <div class="col-6 mt-3 mt-md-0 d-flex flex-row justify-content-end">
                <button v-if="tradeType === 1" :disabled="!permission" type="button" @click="canseleType" class="trade-button-green btn buy set-order mt-0 me-2">
                  <span class="d-flex justify-content-center align-items-center">
                    cancel
                  </span>
                </button>
                <button v-else :disabled="!permission" type="button" @click="setType(1)" class="trade-button-green btn buy set-order mt-0 me-2">
                  <span class="d-flex justify-content-center align-items-center">
                    <span class="ion-md-arrow-up me-2"></span>
                    Change To Green
                  </span>
                </button>
            </div>
            <div class="col-6 mt-3 mt-md-0 d-flex flex-row justify-content-end">
                <button v-if="tradeType === 2" :disabled="!permission" type="button" @click="canseleType" class="trade-button-red btn sell set-order mt-0 me-2">
                  <span class="d-flex justify-content-center align-items-center">
                    cancel
                  </span>
                </button>
                <button v-else :disabled="!permission" type="button" @click="setType(2)" class="trade-button-red btn sell set-order mt-0 me-2">
                  <span class="d-flex justify-content-center align-items-center">
                    <span class="ion-md-arrow-down me-2"></span>
                    Change To Red
                  </span>
                </button>
            </div>
          </div>
       </div>
    </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
  import { WalletCheckCreditRequest } from '@/libs/Api/Wallet';
  import {useVuelidate} from '@vuelidate/core';
  import {required,numeric} from '@vuelidate/validators'
  import {useToast} from "vue-toastification";
  import Helper from '@/libs/Helper';
  import { mapGetters } from "vuex";
import {TradeCreateRequest} from "@/libs/Api/Trade";
export default {
  name: "MarketTradeBox",
  data(){
    return{
      tradeType:null,
      amount:null,
      toast: useToast(),
    }
  },
  validations() {
    return {
      amount: {
        required,
        numeric,
      },
    }
  },
  watch:{
    amount: function (val, oldVal) {
      if(val < 0.001){
        return this.amount = 0.001;
      }
      if(val > this.credit){
        return this.amount = this.credit;
      }
      this.amount = Helper.makeDecimal(val,8);
    },
  },  
  props:{
    permission:{
      type:Boolean,
    },
  },
  computed:{
    ...mapGetters(["Symbol", "SymbolName"]),
    credit() {
      if (this.$store.getters.MyBitUser) {
        return this.$store.getters.MyBitUser.user.credit;
      }
    },
  },
  methods:{
    async validateForm() {
      let _this = this;
      if (_this.tradeType !== null){
        const result = await this.v$.$validate();
        if (!result) {
          return _this.toast.error("Fill amount correctly");
        }
        await _this.checkCredit();
      }else {
        return
      }
    },
    async checkCredit(){
      let _this = this;
      Helper.openLoadingOverlay(_this);

      let data={
        Value:_this.amount,
      };

      let walletCheckCreditRequest = new WalletCheckCreditRequest(_this);
      walletCheckCreditRequest.setParams(data);
      await walletCheckCreditRequest.fetch(async function(content){
        Helper.closeLoadingOverlay(_this);
        if(content === true){
         await _this.createTrade();
        }else {
          return _this.toast.error("Your credit is not enough!");
        }
      },function(error){
        console.log(error);
        Helper.closeLoadingOverlay(_this);
        return _this.toast.error("Your credit is not enough!");
      })
    },
    async createTrade(){
      let _this = this;
      Helper.openLoadingOverlay(_this);

      let data={
        tradeId: 0,
        market: _this.SymbolName,
        type: _this.tradeType,
        userId: 0,
        amount: _this.amount,
        state: 1,
        candleId: 0
      };

      let tradeCreateRequest = new TradeCreateRequest(_this);
      tradeCreateRequest.setParams(data);
      await tradeCreateRequest.fetch(function(content){
        _this.toast.success("Your order hs been sumitted");
        _this.amount = 1;
        _this.tradeType = null;
        Helper.closeLoadingOverlay(_this);
      },function(error){
        console.log(error);
        _this.amount = null;
        _this.tradeType = null;
        Helper.closeLoadingOverlay(_this);
      })
    },
    async setType(param){
      let _this = this;
      const result = await this.v$.$validate();
      if (!result) {
          return _this.toast.error("Fill amount correctly");
        }
        _this.tradeType = param;
    },
    canseleType(){
      this.tradeType = null;
    },
    setHalf(){
      if(this.amount/2 > 0.001){
        this.amount = this.amount/2;
      }else{
        this.toast.error("Your amount shouldn't be less than 0.001 $")
        this.amount = 0.001;
      }
    },
    setDouble(){
      if(this.amount*2 < this.credit){
        this.amount = this.amount*2;
      }else{
        this.toast.error("Your credit is not enough")
        this.amount = this.credit;
      }
    },
    setMin(){
      this.amount = 0.001;
    },
    setMax(){
      this.amount = this.credit;
    },
    changeTradeType(param){
      this.tradeType = param;
    },
    isNumber (evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
      const keyPressed = evt.key;
    
      if (!keysAllowed.includes(keyPressed)) {
           evt.preventDefault();
      }
    },
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
}
</script>

<style scoped>

@media (max-width: 400px) {
  .set-order{
    width:100%;
  }
}

.input-group{
  width: auto;
}
</style>