<template>
  <header class="light-bb">
    <nav class="navbar navbar-expand-lg navbar-light">
      <router-link class="navbar-brand mt-2 d-flex flex-row" to="/">
        <img class="MyBit-logo" src="/img/MyBitLogo.png" alt="logo"/>
        <h2 class="ms-1 mt-1">MyBit</h2>
      </router-link>
      <button
          v-if="showHeader"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#headerNav"
          aria-controls="headerNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div v-if="showHeader" class="collapse navbar-collapse" id="headerNav">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <router-link @click="hideNavbar" class="nav-link active" aria-current="page" to="/">Exchange</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link @click="hideNavbar" class="nav-link" to="/markets">Markets</router-link>
          </li> -->
          <li class="nav-item">
            <router-link @click="hideNavbar" class="nav-link" to="/dashboard/Profile">Dashboard</router-link>
          </li>
          <!--          <li class="nav-item">-->
          <!--            <router-link class="nav-link" to="/chat">Chat</router-link>-->
          <!--          </li>-->
          <!--          <li class="nav-item dropdown">-->
          <!--            <a-->
          <!--              class="nav-link dropdown-toggle"-->
          <!--              href="#"-->
          <!--              id="navbarDropdown1"-->
          <!--              role="button"-->
          <!--              data-bs-toggle="dropdown"-->
          <!--              aria-expanded="false"-->
          <!--            >-->
          <!--              Dashboard-->
          <!--            </a>-->
          <!--            <ul-->
          <!--              class="dropdown-menu dropdown-menu-end"-->
          <!--              aria-labelledby="navbarDropdown1"-->
          <!--            >-->
          <!--              <li>-->
          <!--                <router-link class="dropdown-item" to="profile"-->
          <!--                  >Profile</router-link-->
          <!--                >-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link class="dropdown-item" to="wallet"-->
          <!--                  >Wallet</router-link-->
          <!--                >-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link class="dropdown-item" to="settings"-->
          <!--                  >Settings</router-link-->
          <!--                >-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-item dropdown">-->
          <!--            <a-->
          <!--              class="nav-link dropdown-toggle"-->
          <!--              href="#"-->
          <!--              id="navbarDropdown2"-->
          <!--              role="button"-->
          <!--              data-bs-toggle="dropdown"-->
          <!--              aria-expanded="false"-->
          <!--            >-->
          <!--              Pages-->
          <!--            </a>-->
          <!--            <ul-->
          <!--              class="dropdown-menu dropdown-menu-end"-->
          <!--              aria-labelledby="navbarDropdown2"-->
          <!--            >-->
          <!--              <li>-->
          <!--                <router-link class="dropdown-item" to="/login"-->
          <!--                  >Login</router-link-->
          <!--                >-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link class="dropdown-item" to="/sign-up"-->
          <!--                  >Sign up</router-link-->
          <!--                >-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link class="dropdown-item" to="/lock">Lock</router-link>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link class="dropdown-item" to="/otp-number"-->
          <!--                  >OTP Number</router-link-->
          <!--                >-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link class="dropdown-item" to="/otp-verify"-->
          <!--                  >OTP Verify</router-link-->
          <!--                >-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link class="dropdown-item" to="/reset"-->
          <!--                  >Reset</router-link-->
          <!--                >-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <router-link class="dropdown-item" to="/404">404</router-link>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item header-custom-icon">
            <a href="#" class="btn btn-primary" @click="installer" :style="{'display' : installBtn}">
              Download Desktop App
            </a>
          </li>
          <li class="nav-item header-custom-icon">
            <a href="#" class="nav-link" @click="toggleDark">
              <i v-if="$store.state.theme" class="icon ion-md-moon"></i>
              <i v-else class="icon ion-md-sunny"></i>
            </a>
          </li>
          <!-- <li class="nav-item dropdown header-custom-icon">
            <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown4"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
              <i class="icon ion-md-notifications"></i>
              <span class="circle-pulse"></span>
            </a>
            <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown4"
            >
              <div
                  class="
                  dropdown-header
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <p class="mb-0 font-weight-medium">6 New Notifications</p>
                <a href="#!" class="text-muted">Clear all</a>
              </div>
              <div class="dropdown-body">
                <a href="#!" class="dropdown-item">
                  <div class="icon">
                    <i class="icon ion-md-lock"></i>
                  </div>
                  <div class="content">
                    <p>Account password change</p>
                    <p class="sub-text text-muted">5 sec ago</p>
                  </div>
                </a>
                <a href="#!" class="dropdown-item">
                  <div class="icon">
                    <i class="icon ion-md-alert"></i>
                  </div>
                  <div class="content">
                    <p>Solve the security issue</p>
                    <p class="sub-text text-muted">10 min ago</p>
                  </div>
                </a>
                <a href="#!" class="dropdown-item">
                  <div class="icon">
                    <i class="icon ion-logo-android"></i>
                  </div>
                  <div class="content">
                    <p>Download android app</p>
                    <p class="sub-text text-muted">1 hrs ago</p>
                  </div>
                </a>
                <a href="#!" class="dropdown-item">
                  <div class="icon">
                    <i class="icon ion-logo-bitcoin"></i>
                  </div>
                  <div class="content">
                    <p>Bitcoin price is high now</p>
                    <p class="sub-text text-muted">2 hrs ago</p>
                  </div>
                </a>
                <a href="#!" class="dropdown-item">
                  <div class="icon">
                    <i class="icon ion-logo-usd"></i>
                  </div>
                  <div class="content">
                    <p>Payment completed</p>
                    <p class="sub-text text-muted">4 hrs ago</p>
                  </div>
                </a>
              </div>
              <div
                  class="
                  dropdown-footer
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <a href="#!">View all</a>
              </div>
            </ul>
          </li> -->
          <li class="nav-item dropdown">
            <a
                class="nav-link dropdown-toggle mt-md-1"
                href="#"
                id="navbarDropdown6"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
              Markets
            </a>
            <ul
                class="dropdown-menu dropdown-menu-end p-0"
                aria-labelledby="navbarDropdown6"
            >
              <li class="my-0">
                <p v-for="symbol in symbols" :key="symbol.name" @click="setNewSymbol(symbol)"
                   :class="[symbol.exchangeName === selectedSymbol ? 'text-white bg-primary' : '']"
                   class="dropdown-item cursor-pointer py-2 my-0">
                  <img width="30" height="30" style="margin-right: 10px" :src="'/img/icon/' + symbol.icon" alt="">
                  {{ symbol.name }}
                </p>
              </li>
            </ul>
          </li>
          <li class="nav-item header-custom-icon" title="Your Credit">
            <router-link to="/dashboard/Wallet" class="nav-link">
              <img src="/img/icon/Tether.png" class="me-1" alt="btc" width="20" height="20"/>
              {{ user.credit }} $
            </router-link>
          </li>
          <li v-if="user !== null" class="nav-item dropdown header-img-icon has-after">
            <a
                class="nav-link dropdown-toggle d-flex flex-row justify-content-md-center"
                href="#"
                id="navbarDropdown5"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
              <img
                  :src="user.selfieFileData ? baseUrl+'media/gallery/profile/'+user.selfieFileData : defaultPic"
                  class="header-avatar-image rounded-circle"
                  alt="avatar"
              />
              <p class="name font-weight-bold mb-0 m-2">{{ user.userName }}</p>
            </a>
            <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown5"
            >
              <router-link @click="hideNavbar" to="/dashboard/Profile">
                <div
                    class="dropdown-header d-flex flex-column align-items-center"
                >
                  <div class="figure mb-3">
                    <img class="avatar rounded-circle" :src="user.selfieFileData ? baseUrl+'media/gallery/profile/'+user.selfieFileData : defaultPic" alt=""/>
                  </div>
                  <div class="info text-center">
                    <p class="email text-muted mb-3">{{user.email}}</p>
                  </div>
                </div>
              </router-link>
              <div class="dropdown-body">
                <ul class="profile-nav">
                  <li class="nav-item">
                    <router-link @click="hideNavbar" to="/dashboard/Profile" class="nav-link">
                      <i class="icon ion-md-person"></i>
                      <span>Profile</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link @click="hideNavbar" to="/dashboard/Wallet" class="nav-link">
                      <i class="icon ion-md-wallet"></i>
                      <span>My Wallet</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link @click="hideNavbar" to="/dashboard/Settings" class="nav-link">
                      <i class="icon ion-md-settings"></i>
                      <span>Settings</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link @click="hideNavbar" to="/dashboard/Tickets" class="nav-link">
                      <i class="icon ion-md-contact"></i>
                      <span>Support</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <button @click="logOut" type="button" class="nav-link btn w-100 red text-start">
                      <i class="icon ion-md-power"></i>
                      <span>Log Out</span>
                    </button>
                  </li>
                </ul>
              </div>
            </ul>
          </li>
          <li class="nav-item header-custom-icon">
            <a href="#" class="nav-link" @click="toggleChat">
              <i :class="[$store.state.showChat ? 'text-danger' : '']" class="icon ion-ios-chatboxes"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import {useAuth as auth} from '@websanova/vue-auth';

import Helper from "@/libs/Helper";
import {UsersGetRequest} from "@/libs/Api/Users";
import baseUrl from "@/main";

export default {
  async created() {
    let installPrompt;

    window.addEventListener("beforeinstallprompt",e =>{
      e.preventDefault();
      installPrompt = e;
      this.installBtn = "block";
    });

    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      installPrompt.userChoice.then(result => {
        if (result.outcome === "accepted"){
          console.log("user accepted");
        }else {
          console.log("user denied");
        }
        installPrompt = null;
      })
    }
  },
  data() {
    return {
      installBtn: "none",
      base64ImageSrc: null,
      defaultPic:"/img/avatar.svg",
      installer:undefined,
      user:null,
      baseUrl,
      symbols: [
        {
          name: 'BTCUSDT',
          exchangeName: 'BINANCE:BTCUSDT',
          icon: '18.png'
        },
        {
          name: 'TRXUSDT',
          exchangeName: 'BINANCE:TRXUSDT',
          icon: '6.png'
        },
        {
          name: 'ADAUSDT',
          exchangeName: 'BINANCE:ADAUSDT',
          icon: '8.png'
        },
        {
          name: 'XRPUSDT',
          exchangeName: 'BINANCE:XRPUSDT',
          icon: 'ripple.png'
        },
        {
          name: 'DOGEUSDT',
          exchangeName: 'BINANCE:DOGEUSDT',
          icon: 'dodge.jpg'
        },
        {
          name: 'ETHUSDT',
          exchangeName: 'BINANCE:ETHUSDT',
          icon: '1.png'
        },
      ],
    }
  },
  props: [
    'showHeader'
  ],
  computed: {
    user() {
      if (this.$store.getters.MyBitUser) {
        return this.$store.getters.MyBitUser.user;
      }
    },
    selectedSymbol() {
      return this.$store.getters.Symbol;
    },
    userAvatar() {
      return this.base64ImageSrc
          ? this.base64ImageSrc
          : "/img/avatar.svg";
    },
  },
  methods: {
    hideNavbar() {
      let nav = document.getElementById('headerNav');

      nav.classList.remove('show')
    },
    goTo(param) {
      this.$router.push({name: 'dashboard', params: {id: param}});
    },
    toggleDark: function () {
      this.$store.commit("changeTheme");
    },
    toggleChat: function () {
      this.$store.commit("changeChat");
    },
    logOut() {
      this.hideNavbar();
      this.$store.commit("signOutUser");
      this.$router.push('/login')
    },
    setNewSymbol(param) {
      this.$store.commit("setSymbol", param);
      this.$router.push('/')
      this.hideNavbar();
    }
  },
};
</script>
<style>
.light-bb {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 100;
}
.MyBit-logo{
  margin-top: -5px
}
.avatar{
  width: 70px;
  height: 70px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 50%;
}
</style>
