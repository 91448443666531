<template>
  <div class="settings mtb15">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3">
          <ul
            class="nav nav-tabs nav-pills settings-nav"
            id="myTab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <button
                  @click="goTo('Profile')"
                class="nav-link"
                  :class="component === 'Profile' ? 'active' : ''"
                id="Profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#Profile"
                type="button"
                role="tab"
                aria-controls="Profile"
                aria-selected="true"
              >
                Profile
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                  @click="goTo('Wallet')"
                class="nav-link"
                  :class="component === 'Wallet' ? 'active' : ''"
                id="Wallet-tab"
                data-bs-toggle="tab"
                data-bs-target="#Wallet"
                type="button"
                role="tab"
                aria-controls="Wallet"
                aria-selected="false"
              >
                Wallet
              </button>
            </li>
<!--            <li class="nav-item" role="presentation">-->
<!--              <button-->
<!--                  @click="goTo('Settings')"-->
<!--                  :class="component === 'Settings' ? 'active' : ''"-->
<!--                class="nav-link"-->
<!--                id="Settings-tab"-->
<!--                data-bs-toggle="tab"-->
<!--                data-bs-target="#Settings"-->
<!--                type="button"-->
<!--                role="tab"-->
<!--                aria-controls="Settings"-->
<!--                aria-selected="false"-->
<!--              >-->
<!--                Settings-->
<!--              </button>-->
<!--            </li>-->
            <li class="nav-item" role="presentation">
              <button
                  @click="goTo('Tickets')"
                  :class="component === 'Tickets' ? 'active' : ''"
                  class="nav-link"
                  id="Tickets-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Tickets"
                  type="button"
                  role="tab"
                  aria-controls="Settings"
                  aria-selected="false"
              >
                Support
              </button>
            </li>
          </ul>
        </div>
        <div class="col-lg-9">
          <div class="tab-content" id="myTabContent">

            <Profile
                :class="component === 'Profile' ? 'show active' : ''"
            />

            <Wallet
                :class="component === 'Wallet' ? 'show active' : ''"
            />

            <Setting
                :class="component === 'Settings' ? 'show active' : ''"
            />
            <Tickets
                :class="component === 'Tickets' ? 'show active' : ''"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Profile from "@/components/dashboard/Profile";
import Wallet from "@/components/dashboard/Wallet";
import Setting from "@/components/dashboard/Setting";
import Tickets from "@/views/Ticket/Tickets";
export default {
  components: {Tickets, Setting, Wallet, Profile},
  title: 'MyBit - Dashboard',
  data(){
    return{

    }
  },
  computed:{
    component(){
      return this.$route.params.id
    },
  },
  methods:{
    goTo(param){
      this.$router.push({ name: 'dashboard', params: { id: param } });
    }
  },
}
</script>