import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import message from './message';
import user from './user';

const store = createStore({
  state(){
    return {
      theme: false,
      showChat:true,
      Symbol: 'BINANCE:BTCUSDT',
      SymbolName:'BTCUSDT',
      isLoading: false,
    };
  },
  getters: {
    Symbol(state:any) {
      return state.Symbol;
    },
    SymbolName(state:any) {
      return state.SymbolName;
    },
  },
  mutations: {
    changeTheme(state) {
      if (state.theme) {
        state.theme = false;
        document.body.classList.toggle("dark")
      } else {
        state.theme = true;
        document.body.classList.toggle("dark")
      }
    },
    changeChat(state){
      state.showChat = !state.showChat;
    },
    setSymbol(state, symbol) {
      state.Symbol = symbol.exchangeName;
      state.SymbolName = symbol.name;
    },
    showLoadingOverlay:(state) => {
      state.isLoading = true;
    },
    hideLoadingOverlay:(state) => {
      state.isLoading = false;
    },
  },
  modules:{
    message,
    user,
  },
  plugins: [new VuexPersistence().plugin],
});

export default store;
