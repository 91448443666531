/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default {
    state: () => {
        return {
            SocketUserId: 0,
        };
    },
    getters: {
        SocketId(state) {
            return state.SocketUserId;
        },
    },
    mutations: {
        SetDefaultSocketId(state) {
            state.SocketUserId = 0;
        },
        sendMessage(state, resultId) {
            state.SocketUserId = resultId;
        },
    },
    actions: {
        SetDefaultSocketId(context){
            context.commit('SetDefaultSocketId')
        }
    },
};