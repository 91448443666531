<template>
  <div class="overlay">
    <div class="spinner-border" style="width: 4rem; height: 4rem;color: white" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import Helper from "@/libs/Helper";

export default {
  name: "Loading",
  methods:{
    hide() {
      Helper.closeLoadingOverlay(this);
    },
  },
}
</script>

<style scoped>

</style>