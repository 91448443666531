<template>
  <div
    class="tab-pane fade"
    id="Wallet"
    role="tabpanel"
    aria-labelledby="Wallet-tab"
  >
    <div class="wallet">
      <div class="row">
        <div class="col-lg-4">
          <ul class="settings-nav nav nav-pills nav-tabs">
            <li class="nav-item">
              <a
                href="#"
                class="nav-link d-flex justify-content-between align-items-center active"
                data-bs-toggle="tab"
                data-bs-target="#coinUSDT"
                type="button"
                role="tab"
                aria-controls="coinUSDT"
                aria-selected="true"
              >
                <div class="d-flex">
                  <img src="/img/icon/Tether.png" alt="USDT" />
                  <div>
                    <h2>USDT</h2>
                    <p>Tether</p>
                  </div>
                </div>
                <div>
                  <h3>{{ credit }}</h3>
                  <p class="text-end">
                    <i class="icon ion-md-lock"></i> 0.0000000
                  </p>
                </div>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a
                  href="#"
                  class="
                            nav-link
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                  data-bs-toggle="tab"
                  data-bs-target="#coinTRX"
                  type="button"
                  role="tab"
                  aria-controls="coinTRX"
                  aria-selected="true"
              >
                <div class="d-flex">
                  <img src="/img/icon/6.png" alt="trx"/>
                  <div>
                    <h2>TRX</h2>
                    <p>Tron</p>
                  </div>
                </div>
                <div>
                  <h3>13.454845</h3>
                  <p class="text-end">
                    <i class="icon ion-md-lock"></i> 0.0000000
                  </p>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a
                  href="#"
                  class="
                            nav-link
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                  data-bs-toggle="tab"
                  data-bs-target="#coinADA"
                  type="button"
                  role="tab"
                  aria-controls="coinADA"
                  aria-selected="true"
              >
                <div class="d-flex">
                  <img src="/img/icon/8.png" alt="ada"/>
                  <div>
                    <h2>ADA</h2>
                    <p>Cardano</p>
                  </div>
                </div>
                <div>
                  <h3>35.48428</h3>
                  <p class="text-end">
                    <i class="icon ion-md-lock"></i> 0.0000000
                  </p>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a
                  href="#"
                  class="
                            nav-link
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                  data-bs-toggle="tab"
                  data-bs-target="#coinXRP"
                  type="button"
                  role="tab"
                  aria-controls="coinXRP"
                  aria-selected="true"
              >
                <div class="d-flex">
                  <img src="/img/icon/ripple.png" alt="xrp"/>
                  <div>
                    <h2>XRP</h2>
                    <p>Ripple</p>
                  </div>
                </div>
                <div>
                  <h3>4.458941</h3>
                  <p class="text-end">
                    <i class="icon ion-md-lock"></i> 0.0000000
                  </p>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a
                  href="#"
                  class="
                            nav-link
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                  data-bs-toggle="tab"
                  data-bs-target="#coinDOGE"
                  type="button"
                  role="tab"
                  aria-controls="coinDOGE"
                  aria-selected="true"
              >
                <div class="d-flex">
                  <img src="/img/icon/2.png" alt="btc"/>
                  <div>
                    <h2>DOGE</h2>
                    <p>DOGE</p>
                  </div>
                </div>
                <div>
                  <h3>33.478951</h3>
                  <p class="text-end">
                    <i class="icon ion-md-lock"></i> 0.0000000
                  </p>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a
                  href="#"
                  class="
                            nav-link
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                  data-bs-toggle="tab"
                  data-bs-target="#coinXMR"
                  type="button"
                  role="tab"
                  aria-controls="coinXMR"
                  aria-selected="true"
              >
                <div class="d-flex">
                  <img src="/img/icon/9.png" alt="btc"/>
                  <div>
                    <h2>Bep20</h2>
                    <p>BNB</p>
                  </div>
                </div>
                <div>
                  <h3>99.465975</h3>
                  <p class="text-end">
                    <i class="icon ion-md-lock"></i> 0.0000000
                  </p>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <a
                  href="#"
                  class="
                            nav-link
                            d-flex
                            justify-content-between
                            align-items-center
                          "
                  data-bs-toggle="tab"
                  data-bs-target="#coinErc20"
                  type="button"
                  role="tab"
                  aria-controls="coinErc20"
                  aria-selected="true"
              >
                <div class="d-flex">
                  <img src="/img/icon/1.png" alt="btc"/>
                  <div>
                    <h2>Erc20</h2>
                    <p>ETH</p>
                  </div>
                </div>
                <div>
                  <h3>114.57564</h3>
                  <p class="text-end">
                    <i class="icon ion-md-lock"></i> 0.0000000
                  </p>
                </div>
              </a>
            </li> -->
          </ul>
        </div>

        <div class="col-lg-8">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="coinUSDT"
              role="tabpanel"
            >
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Balances</h5>
                  <ul>
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="icon ion-md-cash"></i>
                        <h2>Total Equity</h2>
                      </div>
                      <div>
                        <h3>{{ credit }} USDT</h3>
                      </div>
                    </li>
                    <li
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <i class="icon ion-md-checkmark"></i>
                        <h2>Available To Trade</h2>
                      </div>
                      <div>
                        <h3>{{ credit }} USDT</h3>
                      </div>
                    </li>
                  </ul>
                  <!-- <button class="btn green">Deposit</button>
                  <button class="btn red">Withdraw</button> -->
                </div>
              </div>
              <!-- <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Wallet Deposit Address</h5>
                  <div class="row wallet-address">
                    <div class="col-md-8">
                      <p>
                        Deposits to this address are unlimited. Note
                        that you may not be able to withdraw all of
                        your funds at once if you deposit more than
                        your daily withdrawal limit.
                      </p>
                      <div class="input-group">
                        <input
                            v-on:focus="$event.target.select()"
                            type="text"
                            class="form-control"
                            ref="myInput"
                            value="Ad87deD4gEe8dG57Ede4eEg5dREs4d5e8f4e"
                        />
                        <div class="input-group-prepend">
                          <button @click="copyText" class="btn btn-primary">
                            COPY
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <img
                          v-if="$store.state.theme"
                          src="/img/qr-code-dark.svg"
                          alt="qr-code"
                      />
                      <img
                          v-else
                          src="/img/qr-code-light.svg"
                          alt="qr-code"
                      />
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Latest Transactions</h5>
                  <div class="wallet-history">
                    <!-- <table class="table">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>25-04-2019</td>
                          <td>
                            <i
                              class="icon ion-md-checkmark-circle-outline green"
                            ></i>
                          </td>
                          <td>4.5454334</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>25-05-2019</td>
                          <td>
                            <i
                              class="icon ion-md-checkmark-circle-outline green"
                            ></i>
                          </td>
                          <td>0.5484468</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>25-06-2019</td>
                          <td>
                            <i class="icon ion-md-close-circle-outline red"></i>
                          </td>
                          <td>2.5454545</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>25-07-2019</td>
                          <td>
                            <i
                              class="icon ion-md-checkmark-circle-outline green"
                            ></i>
                          </td>
                          <td>1.45894147</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>25-08-2019</td>
                          <td>
                            <i class="icon ion-md-close-circle-outline red"></i>
                          </td>
                          <td>2.5454545</td>
                        </tr>
                      </tbody>
                    </table> -->
                    <span class="no-data">
                      <i class="icon ion-md-document mx-0"></i>
                      No data
                    </span>
                  </div>
                </div>
              </div>

              <!-- <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Your Profit</h5>
                  <div class="px-0">
                    
                    <apexchart width="95%" :options="options" :series="options.series"></apexchart>

                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import Helper from '@/libs/Helper';
import { WalletGetCreditRequest } from '@/libs/Api/Wallet';

// @ts-ignore
export default {
  name: "Wallet",
  data() {
    return {
      credit: 0,
      coins: [
        {
          id: "coinBTC",
          symbol: "BTC",
          coin: "Bitcoin",
        },
      ],
      options : {
        series: [{
          name: 'Wagered',
          data: [
            {
              x: 1996,
              y: 162
            },
            {
              x: 1997,
              y: 90
            },
            {
              x: 1998,
              y: 50
            },
            {
              x: 1999,
              y: 77
            },
            {
              x: 2000,
              y: 35
            },
            {
              x: 2001,
              y: -45
            },
            {
              x: 2002,
              y: -88
            },
            {
              x: 2003,
              y: -120
            },
            {
              x: 2004,
              y: -156
            },
            {
              x: 2005,
              y: -123
            },
            {
              x: 2006,
              y: -88
            },
            {
              x: 2007,
              y: -66
            },
            {
              x: 2008,
              y: -45
            },
            {
              x: 2009,
              y: -29
            },
            {
              x: 2010,
              y: -45
            },
            {
              x: 2011,
              y: -88
            },
            {
              x: 2012,
              y: -132
            },
            {
              x: 2013,
              y: -146
            },
            {
              x: 2014,
              y: -169
            },
            {
              x: 2015,
              y: -184
            }
          ]
        }],
        chart: {
          type: 'area',
          height: 300,
          toolbar:{
            show:false,
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Wagered',
          align: 'left',
          style: {
            fontSize: '14px'
          }
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            style: {
              colors: '#8e8da4',
            },
            offsetY: -7,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
        tooltip: {
          x: {
            format: "yyyy",
          },
          fixed: {
            enabled: false,
            position: 'topRight'
          }
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30
            }
          },
          padding: {
            left: 20
          }
        }
      },

    }
  },
  async created() {
    await this.getWallet();
  },
  methods: {
    async getWallet() {
      let _this = this;
      Helper.openLoadingOverlay(_this);

      let walletGetCreditRequest = new WalletGetCreditRequest(_this);
      await walletGetCreditRequest.fetch(
        function (content) {
          _this.credit = content;
          _this.$store.commit("setUserCredit", content);
          Helper.closeLoadingOverlay(_this);
        },
        function (error) {
          console.log(error);
          Helper.closeLoadingOverlay(_this);
        }
      );
    },
    copyText() {
      this.$refs.myInput.focus();
      document.execCommand("copy");

      // Another way to copy :
      // navigator.clipboard.writeText();
    },
  },
};
</script>

<style scoped></style>
