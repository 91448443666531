<template>
  <div class="vh-100 d-flex justify-content-center">
    <div class="form-access my-auto">
      <form>
        <span class="mb-0">OTP Verification</span>
        <p class="text-center mb-4">One time code send on on your number</p>
        <input
          type="text"
          class="form-control"
          placeholder="Enter code here"
          required
        />
        <button type="submit" class="btn btn-primary">Reset</button>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  title: 'MyBit - OtpVerify',
}
</script>

