import WebServiceRequest from './WebServiceRequest'

class AccountSetSliderRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Account/SetSlider')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class AccountGetSliderRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Account/GetSliders')
    }
}
class AccountDeleteSliderRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setPriority(priority){
        super.setTag('Account/DeleteSlider?priority='+priority);
    }
}
class AccountAddUserByAdminRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Account/AddUserByAdmin')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class AccountSignInRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Account/SignIn')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class AccountSignUpRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Account/SignUp')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class AccountSendConfirmationCodeToEmailRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Account/SendConfirmationCodeToEmail')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class AccountValidateConfirmationCodeRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Account/ValidateConfirmationCode')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class AccountCheckUserNameRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Account/CheckUserName')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class AccountForgotPasswordRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Account/ForgotPassword')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
export {
    AccountSetSliderRequest,
    AccountGetSliderRequest,
    AccountDeleteSliderRequest,
    AccountAddUserByAdminRequest,
    AccountSignInRequest,
    AccountSignUpRequest,
    AccountSendConfirmationCodeToEmailRequest,
    AccountValidateConfirmationCodeRequest,
    AccountCheckUserNameRequest,
    AccountForgotPasswordRequest,
}