<template>
  <div class="vh-100 d-flex justify-content-center">
    <div class="form-access mt-10">
      <form>
        <span>Create Account</span>
        <div class="form-group">
          <input
              v-model="v$.payload.userName.$model"
              :class="[v$.payload.userName.$error ? 'border-danger' : '']"
              type="text"
              ref="userName"
              class="form-control"
              placeholder="User Name"
              required
          />
          <span v-if="userExist" class="text-danger text-start mt-2 error-font-size">User name exist !</span>
        </div>
        <div class="form-group">
          <input
              v-model="v$.payload.mail.$model"
              :class="[v$.payload.mail.$error ? 'border-danger' : '']"
              type="email"
              class="form-control"
              placeholder="Email Address"
              required
          />
        </div>
        <div class="form-group">
          <input
              type="password"
              v-model="v$.payload.password.$model"
              :class="[v$.payload.password.$error ? 'border-danger' : '']"
              class="form-control"
              placeholder="Password"
              required
          />
        </div>
        <div class="form-group">
          <input
              type="password"
              v-model="v$.confirmPassword.$model"
              :class="[v$.confirmPassword.$error ? 'border-danger' : '']"
              class="form-control"
              placeholder="Confirm Password"
              required
          />
        </div>
        <div v-if="verificationSent" class="form-group">
          <input
              @keyup.enter="validateForm(2)"
              type="password"
              v-model="payload.verifyCode"
              class="form-control"
              placeholder="Enter verification code"
              required
          />
        </div>
        <div v-if="verificationSent" class="custom-control form-check">
          <input
              v-model="agreement"
              type="checkbox"
              class="form-check-input"
              id="form-checkbox"
              required
          />
          <label class="form-check-label" htmlFor="form-checkbox">
            I agree to the
            <router-link to="/terms-and-conditions"
            >Terms & Conditions
            </router-link
            >
          </label>
        </div>
        <button v-if="!verificationSent" @click="validateForm(1)" type="button" class="btn btn-primary">Send
          Verification code
        </button>
        <button v-else :disabled="isButtonDisabled" @click="validateForm(2)" type="button" class="btn btn-primary">Sign
          Up
        </button>
      </form>
      <h2>
        Already have an account?
        <router-link to="/login"> Sign in here</router-link>
      </h2>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {useVuelidate} from '@vuelidate/core';
import {required, email, minLength, sameAs, requiredIf} from '@vuelidate/validators'
import {useToast} from "vue-toastification";
import {
  AccountCheckUserNameRequest,
  AccountSendConfirmationCodeToEmailRequest,
  AccountSignUpRequest
} from "@/libs/Api/Account";
import Helper from "@/libs/Helper";

export default {
  title: 'MyBit - SignUp',
  data() {
    return {
      verificationSent: false,
      payload: {
        mobile: '',
        mail: "",
        firstName: '',
        lastName: '',
        password: "",
        verifyCode: "",
        encryptedMail: '',
        type: 2,
        userName: "",
        userRole: 1,
        introducerCode: ''
      },
      confirmPassword: '',
      toast: useToast(),
      agreement: false,
      userExist: false,
    }
  },
  validations() {
    return {
      payload: {
        userName: {
          required
        },
        mail: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(8),
        },
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(this.payload.password),
      },
    }
  },
  watch: {
    userName(nv, ov) {
      this.checkUserName(nv);
    },
  },
  computed: {
    userName() {
      return this.payload.userName;
    },
    isButtonDisabled() {
      if (
          this.payload.verifyCode.length < 5 ||
          !this.agreement
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async validateForm(param) {
      let _this = this;
      let result = null;

      result = await _this.v$.$validate();

      if (!result) {
        return _this.toast.error("All fields are required");
      }
      if (param === 1) {
        await _this.sendConfirmationCode();
      } else {
        await _this.signUp();
      }
    },
    async sendConfirmationCode() {
      let _this = this;
      Helper.openLoadingOverlay(_this);
      let data = {
        email: _this.payload.mail,
      };
      let accountSendConfirmationCodeToEmailRequest = new AccountSendConfirmationCodeToEmailRequest(_this);
      accountSendConfirmationCodeToEmailRequest.setParams(data)
      await accountSendConfirmationCodeToEmailRequest.fetch(function (content) {
        Helper.closeLoadingOverlay(_this);

        if (content.message === "Confirmation code has not expired") {
          _this.toast.error("Confirmation code has not expired.");
        } else if (content.hasUser === 1) {
          _this.toast.error("User exist!");
        } else if (content.message === 'network problem') {
          _this.toast.error("network problem");
        } else {
          _this.toast.success("Confirmation code has been sent to you.");
          _this.verificationSent = true;
        }
      }, function (error) {
        Helper.closeLoadingOverlay(_this);
        console.log(error)
      })
    },
    async signUp() {
      let _this = this;
      Helper.openLoadingOverlay(_this);

      let data = JSON.parse(JSON.stringify(_this.payload));

      let accountSignUpRequest = new AccountSignUpRequest(_this);
      accountSignUpRequest.setParams(data);
      await accountSignUpRequest.fetch(function (content) {
        Helper.closeLoadingOverlay(_this);

        if (content.status === 3) {
          return _this.toast.error("User name or password is wrong!");
        } else if (content === 4) {
          _this.toast.error(
              "Your verification code has been expired , try again"
          );
          _this.verificationSent = false;
          return _this.payload.verifyCode = "";
        } else if (content === 5) {
          return _this.toast.error("Verification code is wrong!");
        } else if (content === 6) {
          return _this.toast.error("User not found!");
        } else if (content === 8) {
          return _this.toast.error("User exist!");
        } else if (content === 9) {
          return _this.toast.error("Your password is weak!");
        } else if (content === 10) {
          return _this.toast.error("User name exist!");
        } else if (content === 11) {
          return _this.toast.error("You have been deactivated!");
        } else if (content === 12) {
          return _this.toast.error("Wrong national id!");
        } else if (content === 7) {
          _this.toast.success("You are signed up.");
        }

        _this.toast.success("You are signed up.");
        _this.$store.commit("signUpUser", content);
        _this.$router.push('/');
      }, function (error) {
        Helper.closeLoadingOverlay(_this);
        console.log(error)
      })
    },
    async checkUserName(param) {
      let _this = this;

      let data = {
        userName: param
      };
      let accountCheckUserNameRequest = new AccountCheckUserNameRequest(_this);
      accountCheckUserNameRequest.setParams(data)
      await accountCheckUserNameRequest.fetch(function (content) {
        _this.userExist = false;
        _this.$refs.userName.classList.remove("border-danger")
        _this.$refs.userName.classList.add("border-success")
      }, function (error) {
        _this.userExist = true;
        _this.$refs.userName.classList.add("border-danger")
        console.log(error)
      })
    },
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
}
</script>

