/* eslint-disable */
import Vue from "vue";
// import Vuex from "vuex";
import store from '@/store'
// import inject from 'vue'
// Vue.use(Vuex)
// import VueCompositionAPI, {inject} from '@vue/composition-api'
import { inject } from 'vue'
import { HubConnectionBuilder, LogLevel , HttpTransportType } from "@aspnet/signalr";
// export default (inject) => {
  const hub = new HubConnectionBuilder()
    .withUrl("https://mybitapi.oregonserver.com/chatHub", {
      transport:HttpTransportType.WebSockets,
      skipNegotiation:true,
      accessTokenFactory: function () {
        console.log("Getting token for hub");
      },
    })
    .configureLogging(LogLevel.Information)
    .build();

  inject("hub", hub)

  hub.on("SendMessageTo", (res) => {
    store.commit("sendMessage", res); 
    // console.log("Message sent by", res);
  });

  hub.start().catch(function (err) {
    return console.error(err);
  });

  // with reconnect capability (async/await, not IE11 compatible)
  async function start() {
    try {
      console.log("Attempting reconnect");
      await hub.start();
    } catch (err) {
      console.log(err);
      setTimeout(() => start(), 5000);
    }
  }

  hub.onclose(async () => {
    await start();
  });

  // sample sending of message
  /*
  hub
    .invoke('SendMessageToOthers', {
      action: action,
      data: Object.assign(
        { user: get(store, 'getters.loggedUser.name') },
        message
      )
    })
    .catch(err => console.error(err.toString()))
  */
// }
