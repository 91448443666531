/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default {
    state: () => {
        return {
            myBitUser: {},
        };
    },
    getters: {
        MyBitUser(state) {
            return state.myBitUser;
        }
    },
    mutations: {
        signInUser(state, data) {
            state.myBitUser['user'] = data.data.user;
            state.myBitUser.user['selfieFileData'] = data.photo;
            state.myBitUser.user['credit'] = 0;
            state.myBitUser.user['token'] = data.data.token;
        },
        signUpUser(state, data) {
            state.myBitUser['user'] = data.user;
            state.myBitUser.user['selfieFileData'] = null;
            state.myBitUser.user['credit'] = 0;
            state.myBitUser.user['token'] = data.token;
        },
        signOutUser(state) {
            state.myBitUser = {};
        },
        setUserCredit(state, data){
            state.myBitUser.user['credit'] = data;
        }
    },
    actions: {},
};