<template>
  <div class="container-fluid mtb15 no-fluid position-relative">
    <div class="row sm-gutters position-relative">
      <div class="mb-3 chart-container" :class="[showChat ? 'col-md-9' : 'col-md-12']">
        <div :class="[showLoading ? '' : 'd-none',result === 2 ? 'border border-success green-box-shadow' : '',result === 3 ? 'border border-danger red-box-shadow' : '']"
             class="loading-container d-flex flex-column justify-content-center align-items-center placeHolder">
          <h1 v-if="result !== null" class="mb-2">
            <span v-if="result === 2" class="text-success">You Won Last Match</span>
            <span v-if="result === 3" class="text-danger">You Lost Last Match</span>
          </h1>
          <h2 class="ping">Get ready for next candle...</h2>
          <h3 class="mt-5 ping" :class="[isHurry ? 'text-danger' : 'text-success']">{{ resendCodeTimerSecond }}</h3>
        </div>
        <TradingChart
            :class="[showChart ? '' : 'd-none']"
            v-if="$store.state.theme"
        />
        <TradingChartDark
            :class="[showChart ? '' : 'd-none']"
            v-else
        />
      </div>
      <div class="mb-3 chat-container " :class="[showChat ? 'col-md-3' : 'd-none']">
        <ChatComponent ref="ChatComponent" class="mb-3"/>
        <!-- <button
            class="btn btn-primary toggler d-flex flex-row justify-content-center align-items-center p-0 d-none d-md-block"
            type="button"
            @click="showChat = !showChat"
            v-text="showChat ? '>' : '&lt;'"
        >
        </button> -->
      </div>
      <div class="mb-3" :class="[showChat ? 'col-md-9' : 'col-md-12']" style="transition: 400ms;">
        <MarketTradeBox ref="MarketTradeBox" :permission="permission"/>
      </div>
      <div class="mb-3" :class="[showChat ? 'col-md-9' : 'col-md-12']" style="transition: 400ms;">
        <MarketTrade @getResult="getResult" ref="MarketTrade"/>
      </div>
      <div class="mb-3" :class="[showChat ? 'col-md-9' : 'col-md-12']" style="transition: 400ms;">
        <MarketTradesHistory ref="MarketTradesHistory" @shareTrade="shareTrade"/>
        <!--<MarketHistory class="history"/>-->
      </div>
      <!--      <div class="col-md-12 mb-3">-->
      <!--        <HistoryOrder/>-->
      <!--      </div>-->
      <!--      <div class="col-sm-12 col-md-6">-->
      <!--        <MarketPairs/>-->
      <!--      </div>-->
      <!--      <div class="col-sm-12 col-md-6">-->
      <!--        <OrderBook/>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import TradingChart from "../components/TradingChart.vue";
import TradingChartDark from "../components/TradingChartDark.vue";
import MarketTrade from "../components/MarketTrade.vue";
import {computed, onMounted, ref, reactive} from 'vue'
import MarketTradesHistory from "@/components/MarketTradesHistory";
import ChatComponent from "@/components/ChatComponent";
import MarketTradeBox from "@/components/MarketTradeBox";
import {mapGetters} from "vuex";
import Helper from "@/libs/Helper";
import { TradeCreateRequest, TradeGetMyTradesRequest } from "@/libs/Api/Trade";
import {useToast} from "vue-toastification";
import { WalletGetCreditRequest } from '@/libs/Api/Wallet';

export default {
  title: 'MyBit - Exchange',
  data() {
    return {
      count: 0,
      showChart: true,
      globalTimer:null,
      Timer: null,
      resendCodeTimer: null,
      resendCodeTimerSecond: 7,
      seconds: 0,
      showLoading:false,
      isHurry:false,
      permission:false,
      toast: useToast(),
      result:null,
    }
  },
  computed:{
    ...mapGetters(["Symbol","SymbolName"]),
    user() {
      if (this.$store.getters.MyBitUser) {
        return this.$store.getters.MyBitUser.user;
      }
    },
    showChat(){
      return this.$store.state.showChat;
    },
  },
  created() {
    let _this = this;
   _this.checkTime();
   _this.getWallet();
  },
  async beforeUnmount() {
    await clearInterval(this.globalTimer);
    await clearInterval(this.Timer);
  },
  watch:{
    seconds: function (val, oldVal) {
      let _this = this;
      if (val === 0) {
        _this.showChart = false;
        _this.startPendingTimer(8);
      }
      if(val === 11){
        _this.$refs.MarketTrade.getActiveTrades();
        _this.$refs.MarketTradesHistory.getAllTrades();
        _this.$refs.MarketTradesHistory.getMyTrades('');
      }
    },
    Symbol: function (val,oldVal) {
      this.$refs.MarketTradesHistory.getAllTrades();
      this.$refs.MarketTradesHistory.getMyTrades('');
    },
  },
  methods: {
    checkTime() {
      let _this = this;
      this.globalTimer = setInterval(function () {
        _this.seconds = new Date().getUTCSeconds();
      }, 1000)
    },
    async startPendingTimer(duration) {
      let _this = this;
      if (_this.Timer) return

      let seconds = Number(duration);
      let miliSeconds = 10;
      _this.Timer = setInterval( async () => {
        _this.permission = true;
        miliSeconds--
        if (miliSeconds === 0) {
          seconds--
          miliSeconds = 10
        }
        _this.resendCodeTimerSecond = '0' + seconds + ' : ' + miliSeconds;
        if (seconds === 7 && miliSeconds === 1) {
          _this.showLoading = true;
        }
        if (seconds === 5 && miliSeconds === 1) {
          _this.$refs.MarketTrade.getLastTrades();
          _this.getWallet();
        }
        if (seconds < 5) {
          _this.isHurry = true;
        }
        if (seconds < 0) {
          clearInterval(_this.Timer)
          _this.Timer = null;
          _this.showLoading = false;
          _this.showChart = true;
          _this.resendCodeTimerSecond = 6;
          _this.isHurry = false;
          _this.permission = false;
          _this.result = null;
          await _this.$refs.MarketTradeBox.validateForm();
        }
      }, 100)
    },
    async getWallet() {
      let _this = this;
      // Helper.openLoadingOverlay(_this);

      let walletGetCreditRequest = new WalletGetCreditRequest(_this);
      await walletGetCreditRequest.fetch(
        function (content) {
          _this.credit = content;
          _this.$store.commit("setUserCredit", content);
          // Helper.closeLoadingOverlay(_this);
        },
        function (error) {
          console.log(error);
          // Helper.closeLoadingOverlay(_this);
        }
      );
    },
    getResult(param){
      this.result = param;
    },
    shareTrade(shareText){
      this.$refs.ChatComponent.shareTrade(shareText);
    }
    // changeShow(){
    //   this.showChat = !this.showChat;
    // },
  },
  components: {
    MarketTradeBox,
    ChatComponent,
    TradingChart,
    TradingChartDark,
    MarketTrade,
    MarketTradesHistory,
  },
};
</script>
<style scoped>
.toggler {
  width: 1rem;
  height: 2rem;
  /*background-color: darkslateblue;*/
  color: white;
  position: absolute;
  left: 0;
  top: 50%;
  border: none;
  border-radius: 0;
  transition: 300ms;
  transform: translateY(-50%);
}

.history {
  height: 30vh;
}

.chart-container {
  max-height: 50vh;
  min-height: 50vh;
  transition: 400ms
}

.chat-container {
  position: fixed !important;
  top: 1;
  right: 0;
  min-height: 90vh;
  max-height: 90vh;
  transition: 400ms;
}

.market-trade-container {
  min-height: 41vh;
}

@media (max-width: 576px) {
  .chart-container {
    max-height: 90vh;
    min-height: 90vh;
  }

  .chat-container {
    position: relative !important;
    min-height: 53vh;
  }

  .market-trade-container {
    min-height: max-content;
  }

  .history {
    height: 40vh;
  }
}

.ping {
  animation: load 1s ease-out infinite;
}

@keyframes load {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.green-box-shadow{
  box-shadow: inset 0px 0px 60px 0px #26de81;
}

.red-box-shadow{
  box-shadow: inset 0px 0px 60px 0px #ff231f;
}
</style>
