<template>
  <div class="w-100 d-flex justify-content-between">
    <div
      v-if="myTrade === true"
      class="market-trade mt15 w-100"
    >
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item mx-auto" role="presentation">
          <button
            class="nav-link active"
            id="limit-tab"
            data-bs-toggle="tab"
            data-bs-target="#limit"
            type="button"
            role="tab"
            aria-controls="limit"
            aria-selected="true"
          >
            Your Result
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active pt-3"
          id="limit"
          role="tabpanel"
          aria-labelledby="limit-tab"
        >
          <div
            v-if="lastTrades"
            class="d-flex justify-content-between"
            style="min-height: 10rem; max-height: 20rem"
          >
            <div
              v-if="lastTradesData.state === 1"
              class="market-trade-buy position-relative"
            >
              <div>
                <button
                  v-if="lastTradesData.data.state === 2"
                  type="button"
                  class="btn btn-success w-100 mt-0"
                >
                  You Won
                </button>
                <button
                  v-if="lastTradesData.data.state === 3"
                  type="button"
                  class="btn btn-danger w-100 mt-0"
                >
                  You Lost
                </button>
              </div>
              <table class="table mt-3">
                <thead>
                  <tr>
                    <th>Market</th>
                    <th>Amounts</th>
                    <th>Profit / Loss</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ lastTradesData.data.market }}</td>
                    <td>{{ lastTradesData.data.amount }}</td>
                    <td v-if="lastTradesData.data.state === 2" class="green">
                      {{ lastTradesData.data.profit }} $
                    </td>
                    <td v-if="lastTradesData.data.state === 3" class="red">
                      {{ lastTradesData.data.loss }} $
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="myTrade === false" class="market-trade green-trade mt15" style="width: 49%">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item mx-auto" role="presentation">
          <button
            class="nav-link active text-success"
            id="limit-tab"
            data-bs-toggle="tab"
            data-bs-target="#limit"
            type="button"
            role="tab"
            aria-controls="limit"
            aria-selected="true"
          >
            Green Bank : {{ greenBank }} $
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active border-top border-success pt-3"
          id="limit"
          role="tabpanel"
          aria-labelledby="limit-tab"
        >
          <div
            v-if="lastTrades"
            class="d-flex justify-content-between"
            style="min-height: 10rem; max-height: 20rem"
          >
            <div
              v-if="lastTradesData.state === 2"
              class="market-trade-buy position-relative"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th>Users</th>
                    <th>Amounts</th>
                    <th>Percent</th>
                    <th>Profit / Loss</th>
                  </tr>
                </thead>
                <tbody v-if="lastTradesData.data.winTrades.length > 0">
                  <tr
                    v-for="ut in lastTradesData.data.winTrades"
                    :key="ut.tradeId"
                  >
                    <td>{{ ut.userInfo.userName }}</td>
                    <td>{{ ut.amount }}</td>
                    <td>{{ calculatePercent(greenBank, ut.amount) }} %</td>
                    <td v-if="ut.profit" class="green">{{ ut.profit }} $</td>
                    <td v-if="ut.loss" class="red">{{ ut.loss }} $</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-between"
            style="min-height: 10rem; max-height: 20rem"
          >
            <div class="market-trade-buy position-relative">
              <!-- <div>
              <div class="btn btn-success w-100 mt-0">Green</div>
            </div> -->
              <table class="table">
                <thead>
                  <tr>
                    <th>Users</th>
                    <th>Amounts</th>
                    <th>Percent</th>
                    <th>Profit / Loss</th>
                  </tr>
                </thead>
                <tbody v-if="upTrades.length > 0">
                  <tr v-for="ut in upTrades" :key="ut.tradeId">
                    <td>{{ ut.userInfo.userName }}</td>
                    <td class="green">{{ ut.amount }} $</td>
                    <td>{{ calculatePercent(greenBank, ut.amount) }} %</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="myTrade === false" class="market-trade red-trade mt15" style="width: 49%">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item mx-auto" role="presentation">
          <button
            class="nav-link active text-danger"
            id="limit-tab"
            data-bs-toggle="tab"
            data-bs-target="#limit"
            type="button"
            role="tab"
            aria-controls="limit"
            aria-selected="true"
          >
            Red Bank : {{ redBank }} $
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active border-top border-danger pt-3"
          id="limit"
          role="tabpanel"
          aria-labelledby="limit-tab"
        >
          <div
            v-if="lastTrades"
            class="d-flex justify-content-between"
            style="min-height: 10rem; max-height: 20rem"
          >
            <div
              v-if="lastTradesData.state === 2"
              class="market-trade-buy position-relative"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th>Users</th>
                    <th>Amounts</th>
                    <th>Percent</th>
                    <th>Profit / Loss</th>
                  </tr>
                </thead>
                <tbody v-if="lastTradesData.data.loseTrades.length > 0">
                  <tr
                    v-for="dt in lastTradesData.data.loseTrades"
                    :key="dt.tradeId"
                  >
                    <td>{{ dt.userInfo.userName }}</td>
                    <td>{{ dt.amount }}</td>
                    <td>{{ calculatePercent(redBank, dt.amount) }} %</td>
                    <td v-if="dt.profit" class="green">{{ dt.profit }} $</td>
                    <td v-if="dt.loss" class="red">{{ dt.loss }} $</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-between"
            style="min-height: 10rem; max-height: 20rem"
          >
            <div class="market-trade-buy position-relative">
              <table class="table">
                <thead>
                  <tr>
                    <th>Users</th>
                    <th>Amounts</th>
                    <th>Percent</th>
                    <th>Profit / Loss</th>
                  </tr>
                </thead>
                <tbody v-if="downTrades.length > 0">
                  <tr v-for="dt in downTrades" :key="dt.tradeId">
                    <td>{{ dt.userInfo.userName }}</td>
                    <td class="red">{{ dt.amount }} $</td>
                    <td>{{ calculatePercent(redBank, dt.amount) }} %</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useToast } from "vue-toastification";
import Helper from "@/libs/Helper";
import {
  TradeGetActiveTradesRequest,
  TradeGetLastTradesRequest,
} from "@/libs/Api/Trade";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      upTrades: [],
      downTrades: [],
      toast: useToast(),
      lastTrades: false,
      lastTradesData: [],
      myTrade:false,
      redBank: 0,
      greenBank: 0,
    };
  },
  computed: {
    ...mapGetters(["Symbol", "SymbolName"]),
  },
  async created() {
    await this.getActiveTrades();
  },
  methods: {
    async getLastTrades() {
      let _this = this;
      // Helper.openLoadingOverlay(_this);

      let data = {
        market: _this.SymbolName,
      };

      let tradeGetLastTradesRequest = new TradeGetLastTradesRequest(_this);
      tradeGetLastTradesRequest.setParams(data);
      await tradeGetLastTradesRequest.fetch(
        function (content) {
          _this.lastTradesData = content.result;
          _this.lastTrades = true;
          if (_this.lastTradesData.state === 1) {
            _this.myTrade = true;
            _this.setResult(_this.lastTradesData.data.state);
          }else{
            _this.myTrade = false;
            _this.greenBank = content.result.data.winTotalBank;
            _this.redBank = content.result.data.loseTotalBank;
          }
          // Helper.closeLoadingOverlay(_this);
        },
        function (error) {
          console.log(error);
          // Helper.closeLoadingOverlay(_this);
        }
      );
    },
    async getActiveTrades() {
      let _this = this;
      // Helper.openLoadingOverlay(_this);

      let data = {
        market: _this.SymbolName,
      };

      let tradeGetActiveTradesRequest = new TradeGetActiveTradesRequest(_this);
      tradeGetActiveTradesRequest.setParams(data);
      await tradeGetActiveTradesRequest.fetch(
        function (content) {
          _this.upTrades = content.upTrades;
          _this.downTrades = content.downTrades;
          _this.greenBank = content.upTotalAmount;
          _this.redBank = content.downTotalAmount;
          _this.lastTrades = false;
          _this.myTrade = false;
          // Helper.closeLoadingOverlay(_this);
        },
        function (error) {
          console.log(error);
          // Helper.closeLoadingOverlay(_this);
        }
      );
    },
    calculatePercent(total, amount) {
      let result = (amount / total) * 100;
      return result;
    },
    setResult(param) {
      this.$emit("getResult", param);
    },
    submit() {
      this.$router.push("wallet");
    },
  },
};
</script>
