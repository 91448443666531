<template>
<!--  <router-view />-->
  <router-view v-slot="{ Component }">
    <Header :showHeader="showHeader" />
    <transition name="fade" mode="out-in">
      <component :is="Component"/>
    </transition>
    <Loading v-if="loading"/>
  </router-view>
</template>

<script>
import Header from "../src/components/Header.vue";
import Loading from "@/components/widgets/Loading";
/* eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default {
  data(){
    return{
      // loading:this.$store.state.isLoading,
    }
  },
  computed:{
    loading(){
      return this.$store.state.isLoading;
    },
    showHeader(){
      let _this = this;
      return _this.$route.name !== undefined
          && _this.$route.name !== 'Signup'
          && _this.$route.name !== 'TermsAndConditions'
          && _this.$route.name !== 'Login'
          && _this.$route.name !== 'Notfound'
          && _this.$route.name !== 'Reset';
    }
  },
  created(){
    const theme = this.$store.state.theme;

    if (theme){
      document.body.classList.remove("dark");
    }else {
      document.body.classList.add("dark");
    }
  },
  methods:{

  },
  components: {Loading, Header },
};
</script>
<style>

</style>
