import WebServiceRequest from './WebServiceRequest'

class TicketCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Ticket/CreateTicket')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class TicketDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Ticket/DeleteTicket?ticketId='+id)
    }
}
class TicketGetAllByParentIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag(`Ticket/GetAllByParentId?parentId=${data.id}`)
    }
}
class TicketGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('Ticket/GetAll?pageNumber='+data.pageNumber+'&count='+data.count+'')
    }
}
class TicketGetAllForCustomerRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Ticket/GetAllForCustomer')
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
export {
    TicketCreateRequest,
    TicketDeleteRequest,
    TicketGetAllByParentIdRequest,
    TicketGetAllRequest,
    TicketGetAllForCustomerRequest
};
