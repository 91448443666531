import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Exchange from "../views/Exchange.vue";
import Markets from "../views/Markets.vue";
import Lock from "../views/Lock.vue";
import Login from "../views/Login.vue";
import NewsDetails from "../views/NewsDetails.vue";
import Notfound from "../views/Notfound.vue";
import OtpNumber from "../views/OtpNumber.vue";
import OtpVerify from "../views/OtpVerify.vue";
import Reset from "../views/Reset.vue";
import Signup from "../views/Signup.vue";
import TermsAndConditions from "../views/TermsAndConditions.vue";
import Chat from "../views/Chat.vue";
import Dashboard from "../views/Dashboard.vue";
import Tickets from "../views/Ticket/Tickets.vue";
import Ticket from "../views/Ticket/Ticket.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Exchange",
    component: Exchange,
  },
  {
    path: "/markets",
    name: "Markets",
    component: Markets,
  },
  {
    path: "/lock",
    name: "Lock",
    component: Lock,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/news-details",
    name: "NewsDetails",
    component: NewsDetails,
  },
  {
    path: "/404",
    name: "Notfound",
    component: Notfound,
  },
  {
    path: '/:pathMatch(.*)*',
    name: "Notfound",
    component: Notfound,
  },
  {
    path: "/otp-number",
    name: "OtpNumber",
    component: OtpNumber,
  },
  {
    path: "/otp-verify",
    name: "OtpVerify",
    component: OtpVerify,
  },
  {
    path: "/dashboard/:id",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
  },
  {
    path: "/sign-up",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },
  // {
  //   path: "/Tickets",
  //   name: "Tickets",
  //   component: Tickets,
  // },
  {
    path: "/Tickets/:id",
    name: "Tickets-id",
    component: Ticket,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from,next) => {
  if (Object.keys(store.getters.MyBitUser).length === 0 && to.name !== 'Login' && to.name !== 'TermsAndConditions' && to.name !== 'Reset' && to.name !== 'Signup'){
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router;
